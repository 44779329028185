import React, { useEffect, useState } from "react";
import { Dropdown, NavLink } from "react-bootstrap";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import systemStore, {
  useActiveRole,
  useLoginStatus,
} from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";
import packageInfo from "../../package.json";
import menuActions from "./MenuActions";
import commonService from "../services/CommonService";

function NavBar(props: { toggle: () => void }) {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const loc = useLocation();
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();

  useEffect(() => {
    if (loginStatus.loggedIn) {
      const ax = ma.actions.filter(
        (a) =>
          a.roles.find((r) => loginStatus.hasRole(r)) &&
          !!matchPath(a.url, loc.pathname)
      );

      //console.log(ax);
      if (ax && ax.length > 0) {
        const myRoles = ax[0].roles.filter((r) => loginStatus.hasRole(r));
        if (!myRoles.includes(activeRole as any)) {
          const role = myRoles[0];
          if (!activeRole) {
            setTimeout(() => {
              systemStore.setActiveRole(role);
            }, 100);
          } else {
            systemStore.setActiveRole(role);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.pathname, loginStatus]);

  useEffect(() => {
    if (
      loginStatus.loggedIn &&
      activeRole &&
      !commonService.isNullOrWhitespace(activeRole)
    ) {
      SysServices.http.userSetting.saveLastDashboard(activeRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole]);

  return (
    <>
      {inProgress && <CommonSpinner overlay={true}></CommonSpinner>}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="sideNavToggle btn btn-sm me-1"
            onClick={props.toggle}
          >
            <i className="fa fa-bars"></i>
          </button>
          <span className="navbar-brand">
            <NavLink className="navbar-brand" style={{ padding: 0 }}>
              <img
                src="/webf1.png"
                alt="WebF1"
                style={{ width: "214px" }}
              ></img>
            </NavLink>
          </span>
          <div className="collapse navbar-collapse" id="navbarNav">
            <button
              className="sideNavToggle btn btn-sm me-1"
              onClick={props.toggle}
            >
              <i className="fa fa-bars"></i>
            </button>
            <div className="navbar-center flex flex-center py-2"></div>
            <div className="navbar-end flex gap-10">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-profile">
                  <i className="fa fa-user-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item disabled={true}>My Account</Dropdown.Item>
                  <Dropdown.Divider />
                  {!loginStatus.companySwitched && (
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/change-password", { replace: true });
                      }}
                    >
                      <i className="fa fa-lock me-3"></i>
                      Change Password
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setInProgress(true);
                      SysServices.http.myAccount
                        .logout(SysServices.http.fetcher.getToken().sessionId)
                        .then((data) => {
                          //do nothing...
                        })
                        .catch((err) => {
                          toastStore.showError("Logout Error", err);
                        })
                        .finally(() => {
                          SysServices.http.fetcher.clearToken();
                          systemStore.clearAuthData();
                          setInProgress(false);
                          navigate("/", { replace: true });
                        });
                    }}
                  >
                    <i className="fa fa-sign-out me-3"></i>
                    <span>Log Out</span>
                  </Dropdown.Item>
                  <Dropdown.Header className="text-center">
                    <small>
                      v<span>{packageInfo.version}</span>
                    </small>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
