import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import commonService from "../services/CommonService";

function InputWithSimilaritySearch(props: {
  autoFocus?: boolean;
  currentId?: any;
  value: string;
  onChange: (val: string) => void;
  request: () => Promise<SysModels.TypeAheadOutputDto[]>;
  placeholder: string;
  className?: string;
  readOnly?: boolean;
}) {
  const [showSimilar, setShowSimilar] = useState(false);
  const similar = useFetchHelper(() => props.request(), "Similar Items");

  useEffect(() => {
    const tmo = setTimeout(() => {
      if ((props.value?.length || 0) >= 3) {
        similar.getData();
      } else {
        similar.setData([]);
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div className="flex flex-row">
      <div className="flex-1">
        <input
          className={`form-control ${props.className || ""}`}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus || false}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          onFocus={(e) => setShowSimilar(true)}
          onBlur={(e) => setShowSimilar(false)}
          autoComplete="new-password"
          readOnly={props.readOnly}
        />
      </div>
      <div>
        <Dropdown show={showSimilar}>
          <Dropdown.Toggle
            id={`dropdown-showSimilar-${commonService.getUniqueId()}`}
            tabIndex={-1}
            className="dropdown-toggle-hidden"
            variant="secondary"
            onFocus={(e) => setShowSimilar(true)}
            onBlur={(e) => setShowSimilar(false)}
          >
            <i className="fa fa-info-circle"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu show={showSimilar} align="right">
            <Dropdown.Header>Similar {props.placeholder}(s)</Dropdown.Header>
            {similar.status === FetchStatus.InProgress && (
              <Dropdown.Header>Loading...</Dropdown.Header>
            )}
            {similar.status === FetchStatus.Complete && (
              <>
                {similar.data
                  ?.filter((item) => `${item.id}` !== `${props.currentId}`)
                  ?.map((item) => (
                    <Dropdown.Header
                      key={item.id}
                      title={`${item.label} - ${item.description}`}
                    >
                      <div style={{ maxWidth: "300px" }}>
                        <div>
                          <small>{item.label}</small>
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <small>
                            <i>{item.description}</i>
                          </small>
                        </div>
                      </div>
                    </Dropdown.Header>
                  ))}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default InputWithSimilaritySearch;
