import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";

function ConfigSettings(props: any) {
  const nav = useNavigate();

  const list = useFetchHelper(
    async () => SysServices.http.configSetting.all(),
    "Config Settings"
  );

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="mb-3">
        <h4>Config Settings</h4>
      </div>

      <div className="flex flex-wrap mb-3">
        <div className="flex-1">
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => {
              nav("/config-settings/new");
            }}
          >
            Add Config
          </button>
        </div>
        <div className="flex-1"></div>
      </div>

      {list.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {list.status !== FetchStatus.InProgress && (
        <>
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th>Area</th>
                <th>Section</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {!list.data?.length && (
                <tr>
                  <td colSpan={3}>No Record(s) Found</td>
                </tr>
              )}
              {list?.data?.map((field) => (
                <tr
                  key={`${field.area}-${field.section}`}
                  className="pointer"
                  onClick={(e) => {
                    nav(
                      `/config-settings/${encodeURIComponent(
                        field.area || ""
                      )}/${encodeURIComponent(field.section || "")}`
                    );
                  }}
                >
                  <td>{field.area}</td>
                  <td>{field.section}</td>
                  <td>{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default ConfigSettings;
