import React, { useEffect, useMemo, useState } from "react";
import {
  BoldExtension,
  ItalicExtension,
  MarkdownExtension,
  IframeExtension,
  ImageExtension,
  corePreset,
  UnderlineExtension,
  StrikeExtension,
  HeadingExtension,
  ListItemExtension,
  CodeExtension,
  BlockquoteExtension,
  CodeBlockExtension,
  OrderedListExtension,
  BulletListExtension,
  TaskListExtension,
  TextExtension,
  NodeFormattingExtension,
  LinkExtension,
  TextColorExtension,
  TextHighlightExtension,
} from "remirror/extensions";
import {
  Remirror,
  useRemirror,
  useRemirrorContext,
  ReactComponentExtension,
  TableExtension,
  TableComponents,
  ThemeProvider,
  useActive,
  FloatingToolbar,
  ToolbarItemUnion,
  ComponentItem,
  useAttrs,
  useSelectedText,
  useCurrentSelection,
} from "@remirror/react";
import { Dropdown } from "react-bootstrap";
import DocEditorLinkDialog from "./DocEditorLinkDialog";
import commonService from "../services/CommonService";

function DocEditor(props: {
  content: string;
  onChange?: (content: string) => void;
  onSave?: () => void;
  readonly?: boolean;
  className?: string;
  onDialogOpen?: (open: boolean) => void;
}) {
  const [uid] = useState(commonService.getUniqueId());

  const { manager, state } = useRemirror({
    extensions: () => [
      ...corePreset(),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new StrikeExtension(),
      new HeadingExtension({}),
      new ListItemExtension({}),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new TaskListExtension(),
      new CodeExtension(),
      new CodeBlockExtension({}),
      new BlockquoteExtension(),
      new TextExtension(),
      new TextColorExtension({}),
      new TextHighlightExtension({}),
      new NodeFormattingExtension({}),
      new ReactComponentExtension({}),
      new MarkdownExtension({}),
      new TableExtension({ resizable: !props.readonly }),
      new IframeExtension({ enableResizing: false }),
      new ImageExtension({ enableResizing: !props.readonly }),
      new LinkExtension({ autoLink: true }),
    ],
    content: props.content,
    selection: "start",
    stringHandler: "html",
  });

  return (
    <>
      <ThemeProvider>
        {props.readonly && (
          <>
            <Remirror
              manager={manager}
              initialContent={state}
              placeholder="Content..."
              editable={false}
            ></Remirror>
          </>
        )}
        {!props.readonly && (
          <Remirror
            manager={manager}
            initialContent={state}
            placeholder="Content..."
            onChange={(e) => {
              const txt = e.helpers.getHTML();
              props.onChange && props.onChange(txt);
            }}
          >
            <RemirrorEditor content={props.content} />
            <RemirrorToolbar
              uid={uid}
              onDialogOpen={(open) => {
                props.onDialogOpen && props.onDialogOpen(open);
              }}
            />
            <TableComponents />
          </Remirror>
        )}
      </ThemeProvider>
    </>
  );
}

const RemirrorEditor = (props: { content: any }) => {
  const { getRootProps, setContent } = useRemirrorContext({ autoUpdate: true });

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (!!props.content && !calledOnce) {
      setContent(props.content);
      setCalledOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content]);

  return (
    <div className="remirror-editor-wrapper">
      <div {...getRootProps()} />
    </div>
  );
};

const RemirrorMenuButton = (props: {
  icon?: string;
  label?: string;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <button
      type="button"
      className={`btn btn-sm btn-${props.active ? "secondary" : "light"}`}
      onClick={(e) => {
        props.onClick();
      }}
    >
      {props.icon && <i className={`fa fa-${props.icon}`}></i>}
      {props.label || ""}
    </button>
  );
};

const RemirrorToolbar = ({
  uid,
  onDialogOpen,
}: {
  uid: string;
  onDialogOpen: (open: boolean) => void;
}) => {
  const ctx = useRemirrorContext();
  const commands = ctx.commands;
  const active = useActive();

  const [showLinkDialog, setShowLinkDialog] = useState<{
    type: "link" | "youtube" | "vimeo";
    data?: {
      url: string;
      text: string;
    };
  }>();

  useEffect(() => {
    onDialogOpen(!!showLinkDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLinkDialog]);

  const url = (useAttrs().link()?.href as string) ?? "";
  const urlText = useSelectedText();

  const activeLink = active.link();
  const linkEditItems: ToolbarItemUnion[] = useMemo(
    () => [
      {
        type: ComponentItem.ToolbarGroup,
        label: "Link",
        items: activeLink
          ? [
              {
                type: ComponentItem.ToolbarButton,
                onClick: () => {
                  setShowLinkDialog({
                    type: "link",
                    data: {
                      url: url,
                      text: "",
                    },
                  });
                },
                icon: "pencilLine",
              },
              {
                type: ComponentItem.ToolbarButton,
                onClick: () => {
                  commands.removeLink();
                },
                icon: "linkUnlink",
              },
            ]
          : [],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLink, url]
  );

  const items: ToolbarItemUnion[] = useMemo(
    () => linkEditItems,
    [linkEditItems]
  );

  const colors = [
    "#ffffff",
    "#2a3e4c",
    "#83878d",
    "#2ecd99",
    "#f0c541",
    "#ed6f56",
    "#4e9de6",
    "#f1a1c7",
    "#f3a464",
    "#889362",
  ];

  const [usedColors, setUsedColors] = useState<string[]>([]);

  const refreshUsedColors = () => {
    const list: string[] = []; //...usedColors];
    document
      .querySelectorAll("[data-text-color-mark], [data-text-highlight-mark]")
      .forEach((e) => {
        let color = e.getAttribute("data-text-color-mark");
        if (color && [...list, ...colors].indexOf(color) === -1) {
          list.push(color);
        } else {
          color = e.getAttribute("data-text-highlight-mark");
          if (color && [...list, ...colors].indexOf(color) === -1) {
            list.push(color);
          }
        }
      });
    setUsedColors(list);
  };

  const selChange = useCurrentSelection();
  useEffect(() => {
    refreshUsedColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selChange]);

  const colorTools = [
    {
      id: "textColor",
      icon: "font",
      setColor: (color: string) => {
        commands.setTextColor(color);
        commands.focus();
      },
      removeColor: () => {
        commands.removeTextColor();
        commands.focus();
      },
      isActive: (color?: string) => {
        if (!color) {
          return active.textColor();
        }
        return active.textColor({ color: color });
      },
    },
    {
      id: "highlightColor",
      icon: "font text-highlight",
      setColor: (color: string) => {
        commands.setTextHighlight(color);
        commands.focus();
      },
      removeColor: () => {
        commands.removeTextHighlight();
        commands.focus();
      },
      isActive: (color?: string) => {
        if (!color) {
          return active.textHighlight();
        }
        return active.textHighlight({ color: color });
      },
    },
  ];

  return (
    <>
      <FloatingToolbar
        items={items}
        positioner="always"
        placement="top"
        enabled={true}
        label="Link"
      />
      {!!showLinkDialog && (
        <DocEditorLinkDialog
          type={showLinkDialog.type}
          data={showLinkDialog.data}
          onClose={(data) => {
            if (data) {
              if (showLinkDialog.type === "link") {
                commands.focus();
                if (showLinkDialog.data) {
                  commands.selectLink();
                  commands.updateLink({ href: data.url });
                } else {
                  commands.insertHtml(
                    `<a href="${data.url}">${data.text || data.url}</a>`
                  );
                }
              } else if (showLinkDialog.type === "youtube") {
                commands.addIframe({
                  src: `https://www.youtube.com/embed/${data.videoId}`,
                  width: 560,
                  height: 315,
                });
                commands.focus();
              } else if (showLinkDialog.type === "vimeo") {
                commands.addIframe({
                  src: `https://player.vimeo.com/video/${data.videoId}`,
                  width: 560,
                  height: 315,
                });
                commands.focus();
              }
            } else {
              commands.focus();
            }
            setShowLinkDialog(undefined);
          }}
        ></DocEditorLinkDialog>
      )}
      <input
        className="display-none"
        type="file"
        id={`file${uid}`}
        multiple={false}
        accept="image/*"
        onChange={async (e) => {
          if (e.target.files && e.target.files?.length > 0) {
            const file = e.target.files[0];
            const filePromise = new Promise<string>((resolve, reject) => {
              var reader = new FileReader();
              reader.onloadend = function () {
                resolve(reader.result as any);
              };
              reader.readAsDataURL(file);
            });
            const url = await filePromise;
            commands.focus();
            commands.insertImage({ src: url });
          }
        }}
      />
      <div className="doc-editor-toolbar" style={{ zIndex: 20 }}>
        <div className="btn-group me-2">
          <RemirrorMenuButton
            icon="bold"
            active={active.bold()}
            onClick={() => {
              commands.toggleBold();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="italic"
            active={active.italic()}
            onClick={() => {
              commands.toggleItalic();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="underline"
            active={active.underline()}
            onClick={() => {
              commands.toggleUnderline();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="strikethrough"
            active={active.strike()}
            onClick={() => {
              commands.toggleStrike();
              commands.focus();
            }}
          ></RemirrorMenuButton>
        </div>
        <div className="btn-group me-2">
          {colorTools.map((tool) => (
            <React.Fragment key={tool.id}>
              <input
                type="color"
                id={`${tool.id}${uid}`}
                style={{
                  width: 0,
                  opacity: 0,
                  padding: 0,
                  border: "none",
                }}
                onChange={(e) => {
                  tool.setColor(e.target.value);
                }}
              />
              <Dropdown>
                <Dropdown.Toggle
                  size="sm"
                  variant={tool.isActive() ? "secondary" : "light"}
                >
                  <i className={`fa fa-${tool.icon}`}></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="default-colors-container">
                    {colors.map((c) => (
                      <button
                        key={c}
                        type="button"
                        className={`btn btn-sm ${
                          tool.isActive(c) ? "active-color" : ""
                        }`}
                        style={{
                          backgroundColor: c,
                        }}
                        onClick={(e) => {
                          tool.setColor(c);
                        }}
                      ></button>
                    ))}
                  </div>
                  <div className="default-colors-container">
                    {usedColors.map((c) => (
                      <button
                        key={c}
                        type="button"
                        className={`btn btn-sm ${
                          tool.isActive(c) ? "active-color" : ""
                        }`}
                        style={{
                          backgroundColor: c,
                        }}
                        onClick={(e) => {
                          tool.setColor(c);
                        }}
                      ></button>
                    ))}
                    {tool.isActive() && (
                      <button
                        type="button"
                        className="btn btn-sm remove-color"
                        onClick={(e) => {
                          tool.removeColor();
                        }}
                      >
                        <i className="fa fa-ban"></i>
                      </button>
                    )}
                  </div>
                  <Dropdown.Item
                    onClick={(e) => {
                      document.getElementById(`${tool.id}${uid}`)?.click();
                    }}
                  >
                    <small>More...</small>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          ))}
        </div>
        <div className="btn-group me-2">
          <RemirrorMenuButton
            label="H1"
            active={active.heading({ level: 1 })}
            onClick={() => {
              commands.toggleHeading({ level: 1 });
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            label="H2"
            active={active.heading({ level: 2 })}
            onClick={() => {
              commands.toggleHeading({ level: 2 });
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            label="H3"
            active={active.heading({ level: 3 })}
            onClick={() => {
              commands.toggleHeading({ level: 3 });
              commands.focus();
            }}
          ></RemirrorMenuButton>
        </div>
        <div className="btn-group me-2">
          <RemirrorMenuButton
            icon="quote-left"
            active={active.blockquote()}
            onClick={() => {
              commands.toggleBlockquote();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="code"
            active={active.code()}
            onClick={() => {
              commands.toggleCode();
              commands.focus();
            }}
          ></RemirrorMenuButton>
        </div>
        <div className="btn-group me-2">
          <RemirrorMenuButton
            icon="list-ul"
            active={active.bulletList()}
            onClick={() => {
              commands.toggleBulletList();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="list-ol"
            active={active.orderedList()}
            onClick={() => {
              commands.toggleOrderedList();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="list"
            active={active.taskList()}
            onClick={() => {
              commands.toggleTaskList();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="table"
            active={active.table()}
            onClick={() => {
              commands.createTable({
                rowsCount: 3,
                columnsCount: 3,
                withHeaderRow: false,
              });
              commands.focus();
            }}
          ></RemirrorMenuButton>
        </div>

        <div className="btn-group me-2">
          <RemirrorMenuButton
            icon="align-left"
            active={active.text({ nodeTextAlignment: "left" })}
            onClick={() => {
              commands.leftAlign();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="align-right"
            active={active.text({ nodeTextAlignment: "right" })}
            onClick={() => {
              commands.rightAlign();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="align-center"
            active={active.text({ nodeTextAlignment: "center" })}
            onClick={() => {
              commands.centerAlign();
              commands.focus();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="align-justify"
            active={active.text({ nodeTextAlignment: "justify" })}
            onClick={() => {
              commands.justifyAlign();
              commands.focus();
            }}
          ></RemirrorMenuButton>
        </div>
        <div className="btn-group me-2">
          <RemirrorMenuButton
            icon="link"
            active={active.link()}
            onClick={() => {
              if (active.link()) {
                setShowLinkDialog({
                  type: "link",
                  data: {
                    url: url,
                    text: "",
                  },
                });
              } else {
                setShowLinkDialog({
                  type: "link",
                  data: { url: "", text: urlText || "" },
                });
              }
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="image"
            active={active.image()}
            onClick={() => {
              document.getElementById(`file${uid}`)?.click();
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="youtube-play"
            active={active.iframe()}
            onClick={() => {
              setShowLinkDialog({ type: "youtube" });
            }}
          ></RemirrorMenuButton>
          <RemirrorMenuButton
            icon="vimeo"
            active={active.iframe()}
            onClick={() => {
              setShowLinkDialog({ type: "vimeo" });
            }}
          ></RemirrorMenuButton>
        </div>
      </div>
    </>
  );
};

export default DocEditor;
