import AppAdminDashboard from "../dashboards/AppAdminDashboard";
import SaaSAdminDashboard from "../dashboards/SaaSAdminDashboard";
import Companies from "../pages/Companies";
import Company from "../pages/Company";
import ConfigSetting from "../pages/ConfigSetting";
import ConfigSettings from "../pages/ConfigSettings";
import Dashboard from "../pages/Dashboard";
import Entities from "../pages/Entities";
import Entity from "../pages/Entity";
import EntityField from "../pages/EntityField";
import EntityFields from "../pages/EntityFields";
import EntityTemplate from "../pages/EntityTemplate";
import EntityTemplates from "../pages/EntityTemplates";
import GlobalSiteMessage from "../pages/GlobalSiteMessage";
import ReleaseNote from "../pages/ReleaseNote";
import ReleaseNotes from "../pages/ReleaseNotes";
import ScheduledJobSetting from "../pages/ScheduledJobSetting";
import ScheduledJobSettings from "../pages/ScheduledJobSettings";
import SiteDownMessage from "../pages/SiteDownMessage";
import SystemErrors from "../pages/SystemErrors";
import TemplateCategories from "../pages/TemplateCategories";
import TemplateCategory from "../pages/TemplateCategory";
import User from "../pages/User";
import Users from "../pages/Users";
import UserTemplate from "../pages/UserTemplate";
import UserTemplates from "../pages/UserTemplates";
import UserType from "../pages/UserType";
import UserTypes from "../pages/UserTypes";
import { USER_ROLES } from "../stores/SystemStore";

export interface IMenuAction {
  label: string;
  icon?: string;
  url: string;
  roles: USER_ROLES[];
  hidden?: boolean;
  component?: any;
  dashboard?: boolean;
}

class MenuActions {
  actions: IMenuAction[] = [
    //ROLES
    {
      label: "App Admin",
      url: "/appadmin-dashboard",
      roles: ["AppAdmin"],
      component: AppAdminDashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "SaaS Admin",
      url: "/saasadmin-dashboard",
      roles: ["SaaSAdmin"],
      component: SaaSAdminDashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "Admin",
      url: "/admin-dashboard",
      roles: ["Admin"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "Reminder Manager",
      url: "/remindermanager-dashboard",
      roles: ["ReminderManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "Template Manager",
      url: "/templatemanager-dashboard",
      roles: ["TemplateManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "User",
      url: "/user-dashboard",
      roles: ["User"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
    },
    {
      label: "User Manager",
      url: "/usermanager-dashboard",
      roles: ["UserManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
    },
    //Companies
    {
      label: "Companies",
      url: "/companies",
      icon: "fa-building",
      roles: ["SaaSAdmin"],
      component: Companies,
    },
    {
      label: "Company",
      url: "/companies/:id",
      icon: "fa-building",
      roles: ["SaaSAdmin"],
      component: Company,
      hidden: true,
    },
    //Config Settings
    {
      label: "Config Settings",
      url: "/config-settings",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ConfigSettings,
    },
    {
      label: "Config Setting",
      url: "/config-settings/:area/:section?",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ConfigSetting,
      hidden: true,
    },
    //Entities
    {
      label: "Entities",
      url: "/entities",
      icon: "fa-file-text-o",
      roles: ["ReminderManager"],
      component: Entities,
    },
    {
      label: "Entity",
      url: "/entities/:id",
      icon: "fa-file-text-o",
      roles: ["ReminderManager"],
      component: Entity,
      hidden: true,
    },
    {
      label: "Entity",
      url: "/entities/new/:template",
      icon: "fa-file-text-o",
      roles: ["ReminderManager"],
      component: Entity,
      hidden: true,
    },
    //Entities (USER)
    {
      label: "View Reminders",
      url: "/view-reminders",
      icon: "fa-file-text-o",
      roles: ["User"],
      component: () => <Entities viewOnly={true} />,
    },
    {
      label: "View Reminder",
      url: "/view-reminders/:id",
      icon: "fa-file-text-o",
      roles: ["User"],
      component: () => <Entity viewOnly={true} />,
      hidden: true,
    },
    //Entity Fields
    {
      label: "Entity Fields",
      url: "/entity-fields",
      icon: "fa-edit",
      roles: ["TemplateManager"],
      component: EntityFields,
    },
    {
      label: "Entity Field",
      url: "/entity-fields/:id",
      icon: "fa-edit",
      roles: ["TemplateManager"],
      component: EntityField,
      hidden: true,
    },
    //Entity Templates
    {
      label: "Entity Templates",
      url: "/entity-templates",
      icon: "fa-file-o",
      roles: ["TemplateManager"],
      component: EntityTemplates,
    },
    {
      label: "Entity Templates",
      url: "/entity-templates/:id",
      icon: "fa-file-o",
      roles: ["TemplateManager"],
      component: EntityTemplate,
      hidden: true,
    },
    //Template Categories
    {
      label: "Template Categories",
      url: "/template-categories",
      icon: "fa-files-o",
      roles: ["TemplateManager"],
      component: TemplateCategories,
    },
    {
      label: "Template Categories",
      url: "/template-categories/:id",
      icon: "fa-files-o",
      roles: ["TemplateManager"],
      component: TemplateCategory,
      hidden: true,
    },
    //Users
    {
      label: "Users",
      url: "/users",
      icon: "fa-user",
      roles: ["AppAdmin", "SaaSAdmin", "Admin", "UserManager"],
      component: Users,
    },
    {
      label: "User",
      url: "/users/:id",
      icon: "fa-user",
      roles: ["AppAdmin", "SaaSAdmin", "Admin", "UserManager"],
      component: User,
      hidden: true,
    },
    //User Templates
    {
      label: "User Templates",
      url: "/user-templates",
      icon: "fa-user",
      roles: ["ReminderManager"],
      component: UserTemplates,
    },
    {
      label: "User Template",
      url: "/user-templates/:username",
      icon: "fa-user",
      roles: ["ReminderManager"],
      component: UserTemplate,
      hidden: true,
    },
    //Template Reminder
    {
      label: "Template Reminders",
      url: "/template-reminders",
      icon: "fa-file-o",
      roles: ["ReminderManager"],
      component: () => "Template Reminder",
    },
    //Template Category Reminder
    {
      label: "Template Category Reminders",
      url: "/category-reminders",
      icon: "fa-files-o",
      roles: ["ReminderManager"],
      component: () => "Template Category Reminder",
    },
    //User Types
    {
      label: "User Types",
      url: "/user-types",
      icon: "fa-users",
      roles: ["TemplateManager"],
      component: UserTypes,
    },
    {
      label: "User Type",
      url: "/user-types/:id",
      icon: "fa-users",
      roles: ["TemplateManager"],
      component: UserType,
      hidden: true,
    },
    //System Logs
    {
      label: "System Logs",
      url: "/system-logs",
      icon: "fa-server",
      roles: ["AppAdmin"],
      component: SystemErrors,
    },
    //Release Notes
    {
      label: "Release Notes",
      url: "/release-notes-maint",
      icon: "fa-sticky-note-o",
      roles: ["AppAdmin"],
      component: () => <ReleaseNotes forAppAdmin={true} />,
    },
    {
      label: "Release Notes",
      url: "/release-notes-maint/:id",
      icon: "fa-sticky-note-o",
      roles: ["AppAdmin"],
      component: ReleaseNote,
      hidden: true,
    },
    {
      label: "Release Notes",
      url: "/release-notes",
      icon: "fa-sticky-note-o",
      roles: [
        "SaaSAdmin",
        "Admin",
        "ReminderManager",
        "TemplateManager",
        "User",
        "UserManager",
      ],
      component: ReleaseNotes,
    },
    //Global Site Message
    {
      label: "Global Site Message",
      url: "/global-site-message",
      icon: "fa-bullhorn",
      roles: ["AppAdmin"],
      component: GlobalSiteMessage,
    },
    //Site Down Message
    {
      label: "Site Down Message",
      url: "/site-down-message",
      icon: "fa-warning",
      roles: ["AppAdmin"],
      component: SiteDownMessage,
    },
    //Scheduled Job Settings
    {
      label: "Scheduled Job Settings",
      url: "/scheduled-job-settings",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ScheduledJobSettings,
    },
    {
      label: "Scheduled Job Setting",
      url: "/scheduled-job-settings/:jobname",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ScheduledJobSetting,
      hidden: true,
    },
  ];
}

const menuActions = new MenuActions();
export default menuActions;
