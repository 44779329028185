import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import FormModal from "../components/FormModal";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import { useLastPageFilters } from "../stores/SystemStore";

function Entities(props: { viewOnly?: boolean }) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      (props.viewOnly
        ? SysServices.http.entity.list
        : SysServices.http.entity.listReminderManager)(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
        }
      ),
    "Entities"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  const [showPickTemp, setShowPickTemp] = useState(false);
  const [itemTemplates, setItemTemplates] = useState([] as any[]);
  const [isLoadingTemps, setIsLoadingTemps] = useState(false);
  const refCateg = React.createRef<any>();
  const handleSearchTemps = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemTemplates([]);
      setIsLoadingTemps(false);
      return;
    }
    setIsLoadingTemps(true);
    await SysServices.http.entityTemplate
      .typeAheadForEntity({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemTemplates(options);
        setIsLoadingTemps(false);
      })
      .catch((err) => {
        setItemTemplates([]);
        setIsLoadingTemps(false);
      });
  };

  const [temp, setTemp] = useState<{ id: string; name: string }>();

  return (
    <div>
      <div className="mb-3">
        <h4>{props.viewOnly ? "View Reminders" : "Entities"}</h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {true && (
        <FormModal
          isOpen={showPickTemp}
          title="Entity Template"
          size="md"
          close={() => {
            setShowPickTemp(false);
            setTemp(undefined);
          }}
          submit={() => {
            temp && nav(`/entities/new/${temp.id}`);
          }}
          disableSubmit={!temp}
          submitButtonLabel="Continue"
        >
          <div className="pb-2">
            <label className="mb-2">Please pick an Entity Template:</label>
            <AsyncTypeahead
              id="typeahead-search-template"
              labelKey="name"
              renderMenuItemChildren={(option, props, index) => (
                <TyeaheadItem {...itemTemplates[index]}></TyeaheadItem>
              )}
              onSearch={handleSearchTemps}
              onChange={(data) => {
                if (data.length > 0) {
                  setTemp({
                    id: (data[0] as any).id,
                    name: (data[0] as any).name,
                  });
                  (refCateg.current as any)?.clear();
                }
              }}
              searchText={"Searching..."}
              isLoading={isLoadingTemps}
              options={itemTemplates}
              placeholder="Search and Pick Entity Template"
              minLength={1}
              delay={500}
              useCache={false}
              filterBy={() => true}
              ref={refCateg}
              autoFocus={true}
            />
            {temp && (
              <div className="alert alert-sm alert-primary mt-2 p-2 px-3 mb-0">
                <strong>{temp.name}</strong>
              </div>
            )}
          </div>
        </FormModal>
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              {!props.viewOnly && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setShowPickTemp(true);
                  }}
                >
                  Add Entity
                </button>
              )}
            </div>
            <div className="flex-1"></div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Template</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={3}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.entities?.map((field) => (
                    <tr
                      key={field.id}
                      className="pointer"
                      onClick={(e) => {
                        if (props.viewOnly) {
                          nav(`/view-reminders/${field.id}`);
                        } else {
                          nav(`/entities/${field.id}`);
                        }
                      }}
                    >
                      <td>
                        {field.lastName}, {field.firstName}
                      </td>
                      <td>{field.entityTemplateName}</td>
                      <td>{field.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="Entities"
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Entities;
