import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import SysModels from "../models";

dayjs.extend(utc);

export const BOOLEAN_FILTERS = ["All", "Yes", "No"];
export const GetDisplayForBooleanFilter = (val?: boolean) => {
  if (val === true) return "Yes";
  if (val === false) return "No";
  return "All";
};

class CommonService {
  devRef = "DevRef 0";
  isEmployeeSite = false;

  getEnvConfig = (name: string) => {
    const fromDocker = (window as any).dockerConfig?.[name] || "";
    if (!this.isNullOrWhitespace(fromDocker)) {
      return fromDocker;
    }
    return process.env[name];
  };

  validations = {
    numberOnly: (val: string) => {
      if (val) {
        return /^\d+$/.test(`${val}`);
      }
      return true;
    },
    twoDecimalsOnly: (val: string) => {
      if (val) {
        return /^\d+\.?\d{0,2}$/.test(`${val}`);
      }
      return true;
    },
    oneDecimalsOnly: (val: string) => {
      if (val) {
        return /^\d+\.?\d{0,1}$/.test(`${val}`);
      }
      return true;
    },
  };

  regexPatterns = {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  isNullOrEmpty(val: string) {
    return val === null || val === undefined || val === "";
  }

  isNullOrWhitespace(val: string) {
    return val === null || val === undefined || `${val || ""}`.trim() === "";
  }

  getUniqueId = () => {
    return (
      dayjs().format("YYYYMMDD-HH-mm-ss-ms-") +
      `${(Math.random() * 123456).toFixed(0)}`
    );
  };

  parseErrorMessage(data: any) {
    if (typeof data === "string") {
      return data;
    }
    let msg = "";
    if (data?.errors?.length > 0) {
      msg = data.errors.map((e: any) => e.message).join("<br/>");
    } else if ((data as any)?.Errors?.length > 0) {
      msg = (data as any).Errors.map((e: any) => e.Message)
        .filter((m: string) => (m || "").trim() !== "")
        .join("<br/>");
    } else {
      msg = JSON.stringify(data);
    }

    const errCode = this.getErrorStatusCode(data);
    return msg === "{}" ? "" : errCode === 404 ? `${msg} Not Found` : msg;
  }

  getErrorStatusCode = (data: any) => {
    //SysModels.IHttpErrorOutputDto
    if (data.statusCode) {
      return data.statusCode;
    }
    return Number((data as any)["StatusCode"]);
  };

  getErrorSeverityEnum = (data: any) => {
    if (data.statusCode) {
      return data.errorSeverityEnum as SysModels.ErrorSeverityEnum;
    }
    return Number(
      (data as any)["ErrorSeverityEnum"]
    ) as SysModels.ErrorSeverityEnum;
  };

  sortByName = (a: { name: string }, b: { name: string }) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  sortByStringProperty = (propertyName: string) => {
    return (a: any, b: any) => {
      return a[propertyName]
        .toLowerCase()
        .localeCompare(b[propertyName].toLowerCase());
    };
  };

  sortByNumericProperty = (propertyName: string, desc = false) => {
    return (a: any, b: any) => {
      if (desc) {
        return b[propertyName] - a[propertyName];
      }
      return a[propertyName] - b[propertyName];
    };
  };

  /**
   * Converts Date to Formatted LOCAL String value
   * @param utcDate UTC Date Value
   * @param format use "full", "date", "time" to set default formats or custom like "MM-DD-YYYY"
   * @returns
   */
  toLocalDate(utcDate: any, format?: string) {
    if (format === "full") {
      format = "ddd, MMM DD, YYYY hh:mm A";
    } else if (format === "date") {
      format = "ddd, MMM DD, YYYY";
    } else if (format === "time") {
      format = "hh:mm A";
    }
    return dayjs
      .utc(utcDate)
      .local()
      .format(format || "ddd, MMM DD, YYYY");
  }

  /**
   * Converts Date to Formatted String value (Optional Convert to Local)
   * @param date Date Value
   * @param format use "full", "date", "time" to set default formats or custom like "MM-DD-YYYY"
   * @param toLocal convert to local date
   */
  toDateString(date: any, format?: string, toLocal = false) {
    if (toLocal) {
      return this.toLocalDate(date, format);
    }
    if (format === "full") {
      format = "ddd, MMM DD, YYYY hh:mm A";
    } else if (format === "date") {
      format = "ddd, MMM DD, YYYY";
    } else if (format === "time") {
      format = "hh:mm A";
    }
    return dayjs(date).format(format || "ddd, MMM DD, YYYY");
  }

  get isSmallScreen() {
    return window.innerWidth < 992;
  }

  toMoney(val: any, currency?: string) {
    return (
      `${currency || "$"}` +
      Number(Number(val || 0).toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  toNumberWithComma(val: any, decimalPlaces?: number) {
    return Number(Number(val || 0).toFixed(decimalPlaces || 2)).toLocaleString(
      undefined,
      {
        maximumFractionDigits: decimalPlaces || 2,
        ...(decimalPlaces
          ? {
              minimumFractionDigits: decimalPlaces,
            }
          : {}),
      }
    );
  }

  b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  crToBr = (value: string) => {
    value = value.replace(/^\s+|\s+$/g, "");
    value = value.replace(/\r?\n/g, "<br />\n");
    return value;
  };

  parseJwt = (token: string) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  focusInput(id: string, timeout = 500) {
    setTimeout(() => {
      document.getElementById(id)?.focus();
    }, timeout || 500);
  }

  isNumberNullOrMax = (num: number) => {
    return num === undefined || num === null || num >= 2147483647;
  };

  clearCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload();
  };

  isStringEqual = (val1: any, val2: any) => {
    return (
      `${val1 || ""}`.toLowerCase().trim() ===
      `${val2 || ""}`.toLowerCase().trim()
    );
  };

  isSameRoleName = (role1: any, role2: any) => {
    return (
      `${role1 || ""}`.replaceAll(" ", "").toLowerCase().trim() ===
      `${role2 || ""}`.replaceAll(" ", "").toLowerCase().trim()
    );
  };

  getFormErrors = (formId: string) => {
    const form = document.getElementById(formId);
    const fields = form?.querySelectorAll(".form-control");
    let errors: string[] = [];
    let focusEl = false;
    fields?.forEach((e) => {
      // if (e.nodeName === "SELECT") {
      //   const el = e as HTMLSelectElement;
      //   console.log(`SELECT: ${el.value}`)
      // }
      if (
        e.classList.contains("required") &&
        this.isNullOrWhitespace((e as any).value)
      ) {
        const el = e as HTMLElement;
        if (!focusEl) {
          focusEl = true;
          el.focus();
        }
        const placeHolder = el.getAttribute("placeholder") || "";
        errors.push(
          placeHolder
            ? `${placeHolder} is required.`
            : `Please fill-in required field(s)`
        );
      }
    });
    return errors;
  };
}

const commonService = new CommonService();
export default commonService;
