import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import commonService from "../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";

function Company(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<
    SysModels.CompanyCreateDto & SysModels.CompanyUpdateDto
  >({
    companyName: "",
    companyAddress: {},
    active: true,
  });

  const timezones = useFetchHelper(SysServices.http.timeZone.get, "Timezones");
  const current = useFetchHelper(
    async () => SysServices.http.company.get(id || ""),
    "Company"
  );

  const validateForm = () => {
    const err = commonService.getFormErrors("companyForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (!validateForm()) return;

    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.company
        .update(id, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Company Saved", "success");
          nav("/companies");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Company", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.company
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Company Saved", "success");
          nav("/companies");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Company", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    timezones.getData();
    if (id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data as any);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/companies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showLoadData, setShowLoadData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const refTz = React.createRef<any>();

  return (
    <div>
      <ConfirmDialog
        show={showDel}
        title="Delete Company"
        message="Do you really want to delete this Company?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.company
              .delete(id || "")
              .then((data) => {
                toastStore.showToast("Company Deleted", "success");
                nav("/companies");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Company", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={showLoadData}
        title="Load Data"
        message="Do you really want to load data to this Company?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setLoadingData(true);
            SysServices.http.company
              .loadData(id as any)
              .then((data) => {
                toastStore.showToast("Loading Data Stared", "success");
              })
              .catch((err) => {
                toastStore.showError("Failed Loading Data", err);
              })
              .finally(() => {
                setLoadingData(false);
              });
          }
          setShowLoadData(false);
        }}
      ></ConfirmDialog>

      <h4>{id === "new" ? "Add" : "Edit"} Company</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {loadingData && (
        <CommonSpinner message="Loading Data..." overlay={true}></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || id === "new") && (
        <div id="companyForm">
          <div className="flex flex-wrap gap-20">
            <div className="pt-2" style={{ maxWidth: "400px", width: "100%" }}>
              <div className="mb-2">
                <div className="mb-1">
                  <label className="required-label">Company Name</label>
                </div>
                <input
                  autoFocus={true}
                  className="form-control required"
                  placeholder="Company Name"
                  value={model.companyName}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyName: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Address 1</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Address 1"
                  value={model.companyAddress?.address1}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          address1: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Address 2</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Address 2"
                  value={model.companyAddress?.address2}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          address2: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label className="required-label">City</label>
                </div>
                <input
                  className="form-control required"
                  placeholder="City"
                  value={model.companyAddress?.city}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          city: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>State</label>
                </div>
                <input
                  className="form-control"
                  placeholder="State"
                  value={model.companyAddress?.state}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          state: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Postal Code</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Postal Code"
                  value={model.companyAddress?.postalCode}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          postalCode: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Country</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Country"
                  value={model.companyAddress?.country}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        companyAddress: {
                          ...prev.companyAddress,
                          country: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>

              <div className="mb-3">
                <div className="mb-1">
                  <label className="required-label">Timezone</label>
                </div>
                {/* <select
                  className="form-control required"
                  value={model.timeZone}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        timeZone: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="">- Select Timezone -</option>
                  {timezones.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select> */}
                <div className="mb-2">
                  <AsyncTypeahead
                    id="typeahead-search-timezone"
                    labelKey="label"
                    onSearch={() => {}}
                    onChange={(data) => {
                      if (data.length > 0) {
                        const item = data[0] as any;
                        setModel((prev) => {
                          return {
                            ...prev,
                            timeZone: item.label,
                          };
                        });
                        (refTz.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={false}
                    options={timezones.data || []}
                    placeholder="Search Timezone"
                    minLength={0}
                    delay={500}
                    useCache={true}
                    //filterBy={() => true}
                    ref={refTz}
                  />
                  <HiddenInputRequiredRef
                    value={model.timeZone}
                    placeholder="Timezone"
                    onFocus={() => refTz.current?.focus()}
                  />
                </div>
                {model.timeZone && (
                  <div className="alert alert-sm alert-secondary p-2">
                    {
                      timezones.data?.find((tz) => tz.label === model.timeZone)
                        ?.label
                    }
                  </div>
                )}
              </div>

              {id === "new" && (
                <>
                  <div className="mb-1">
                    <label>Main Contact's Detail</label>
                  </div>
                  <div className="alert alert-sm alert-light">
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Name</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Main Contact Name"
                        value={model.companyAddress?.mainContactName}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactName: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Email</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Main Contact Email"
                        type="email"
                        value={model.companyAddress?.mainContactEmail}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactEmail: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label>Phone</label>
                      </div>
                      <input
                        className="form-control"
                        placeholder="Main Contact Phone"
                        value={model.companyAddress?.mainContactPhone}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactPhone: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="pt-2 mb-2">
                    <div className="mb-1">
                      <SwitchButton
                        uncheckedLabel="Active"
                        checked={model.active || false}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              active: val || false,
                            };
                          });
                        }}
                      ></SwitchButton>
                    </div>
                  </div>
                </>
              )}
            </div>

            {id !== "new" && (
              <>
                <div
                  className="pt-2"
                  style={{ maxWidth: "400px", width: "100%" }}
                >
                  <div className="mb-1">
                    <label>Main Contact's Detail</label>
                  </div>
                  <div className="alert alert-sm alert-light">
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Name</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Main Contact Name"
                        value={model.companyAddress?.mainContactName}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactName: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Email</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Main Contact Email"
                        type="email"
                        value={model.companyAddress?.mainContactEmail}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactEmail: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label>Phone</label>
                      </div>
                      <input
                        className="form-control"
                        placeholder="Main Contact Phone"
                        value={model.companyAddress?.mainContactPhone}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              companyAddress: {
                                ...prev.companyAddress,
                                mainContactPhone: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="pt-2 mb-2">
                    <div className="mb-1">
                      <SwitchButton
                        uncheckedLabel="Active"
                        checked={model.active || false}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              active: val || false,
                            };
                          });
                        }}
                      ></SwitchButton>
                    </div>
                  </div>
                  <div className="pt-2 mb-2">
                    <div className="mb-1">
                      <SwitchButton
                        uncheckedLabel="Archived"
                        checked={model.archived || false}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              archived: val || false,
                            };
                          });
                        }}
                      ></SwitchButton>
                    </div>
                  </div>
                  <div className="pt-2 mb-2">
                    <div className="mb-1">
                      <hr />
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={(e) => {
                          setShowLoadData(true);
                        }}
                        disabled={loadingData}
                      >
                        {loadingData ? "Loading Data..." : "Load Data"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {id === "new" && (
              <div
                className="pt-2"
                style={{ maxWidth: "400px", width: "100%" }}
              >
                <div className="mb-1">
                  <label>Admin's Detail</label>
                </div>
                <div className="alert alert-sm alert-light">
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">First Name</label>
                    </div>
                    <input
                      className="form-control required"
                      placeholder="First Name"
                      value={model.firstName}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            firstName: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">Last Name</label>
                    </div>
                    <input
                      className="form-control required"
                      placeholder="Last Name"
                      value={model.lastName}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            lastName: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">Email</label>
                    </div>
                    <input
                      className="form-control required"
                      placeholder="Email"
                      value={model.email}
                      type="email"
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            email: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">Username</label>
                    </div>
                    <input
                      className="form-control required"
                      placeholder="Username"
                      value={model.userName}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            userName: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">Password</label>
                    </div>
                    <input
                      className="form-control required"
                      placeholder="Password"
                      type="text"
                      value={model.password}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            password: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <div className="pt-2">
              <button
                className="btn btn-sm btn-primary float-right"
                type="button"
                onClick={(e) => {
                  save();
                }}
                disabled={saving || deleting}
              >
                {saving ? "Saving..." : "Submit"}
              </button>
              <button
                className="btn btn-sm btn-secondary me-2 float-right"
                type="button"
                onClick={(e) => {
                  nav("/companies");
                }}
                disabled={saving || deleting}
              >
                Cancel
              </button>

              {id !== "new" && (
                <button
                  className="btn btn-sm btn-danger"
                  type="button"
                  onClick={(e) => {
                    setShowDel(true);
                  }}
                  disabled={saving || deleting}
                >
                  {deleting ? "Deleting..." : "Delete"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Company;
