import SysModels from "../../models";
import fetcher from "../Fetcher";

class SiteDownMessageService {
  get = async () => {
    const url = `/SiteDownMessage`;
    return fetcher.get<SysModels.SiteDownMessageDto>(url);
  };

  getDisplay = async () => {
    const url = `/SiteDownMessage/Display`;
    return fetcher.get<SysModels.SiteDownMessageDto>(url, { notoken: true });
  };

  save = async (data: SysModels.SiteDownMessageDto) => {
    const url = `/SiteDownMessage`;
    return fetcher.post<SysModels.SiteDownMessageDto>(url, data);
  };
}

const siteDownMessage = new SiteDownMessageService();
export default siteDownMessage;
