import SysModels from "../../models";
import fetcher from "../Fetcher";

class GlobalSiteMessageService {
  get = async () => {
    const url = `/GlobalSiteMessage`;
    return fetcher.get<SysModels.GlobalSiteMessageDto>(url);
  };

  getDisplay = async () => {
    const url = `/GlobalSiteMessage/Display`;
    return fetcher.get<SysModels.GlobalSiteMessageDisplayDto>(url);
  };

  save = async (data: SysModels.GlobalSiteMessageDto) => {
    const url = `/GlobalSiteMessage`;
    return fetcher.post<SysModels.GlobalSiteMessageDisplayDto>(url, data);
  };
}

const globalSiteMessage = new GlobalSiteMessageService();
export default globalSiteMessage;
