import SysModels from "../../models";
import fetcher from "../Fetcher";

class HowToService {
  get = async (id: string) => {
    const url = `/HowTo/${id}`;
    return fetcher.get<SysModels.HowToOutputDraftDto>(url);
  };

  create = async (data: SysModels.HowToInputDto) => {
    const url = `/HowTo`;
    return fetcher.post<SysModels.HowToOutputDraftDto>(url, data);
  };

  update = async (id: string, data: SysModels.HowToInputDto) => {
    const url = `/HowTo/${id}`;
    return fetcher.put<SysModels.HowToOutputDraftDto>(url, data);
  };

  delete = async (id: string) => {
    const url = `/HowTo/${id}`;
    return fetcher.delete<any>(url);
  };

  publish = async (id: string) => {
    const url = `/HowTo/Publish/${id}`;
    return fetcher.put<any>(url);
  };
}

const howTo = new HowToService();
export default howTo;
