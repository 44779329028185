import SysModels from "../../models";
import fetcher from "../Fetcher";

class ScheduledJobSettingService {
  get = async (jobName: string) => {
    const url = `/ScheduledJobSetting/${jobName}`;
    return fetcher.get<SysModels.ScheduledJobSettingDto>(url);
  };

  list = async () => {
    const url = `/ScheduledJobSetting/All`;
    return fetcher.get<SysModels.ScheduledJobSettingListDto[]>(url);
  };

  update = async (
    jobName: string,
    model: SysModels.ScheduledJobSettingUpdateDto
  ) => {
    const url = `/ScheduledJobSetting/${jobName}`;
    return fetcher.put<SysModels.ScheduledJobSettingDto>(url, model);
  };

  delete = async (jobName: string) => {
    const url = `/ScheduledJobSetting/${jobName}`;
    return fetcher.delete<SysModels.ScheduledJobSettingDto>(url);
  };

  start = async (jobName: string) => {
    const url = `/ScheduledJobSetting/Start/${jobName}`;
    return fetcher.put<any>(url);
  };
}

const scheduledJobSetting = new ScheduledJobSettingService();
export default scheduledJobSetting;
