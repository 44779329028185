import SysModels from "../../models";
import fetcher from "../Fetcher";

class CompanyService {
  get = async (id: string) => {
    const url = `/Company/${id}`;
    return fetcher.get<SysModels.CompanyOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.CompanySearchDto
  ) => {
    const url = `/Company/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.CompanyGridDto>(url, model);
  };

  create = async (model: SysModels.CompanyCreateDto) => {
    const url = `/Company`;
    return fetcher.post<any>(url, model);
  };

  update = async (id: string, model: SysModels.CompanyUpdateDto) => {
    const url = `/Company/${id}`;
    return fetcher.put<any>(url, model);
  };

  delete = async (id: string) => {
    const url = `/Company/${id}`;
    return fetcher.delete<any>(url);
  };

  loadData = async (id: string) => {
    const url = `/Company/LoadData/${id}`;
    return fetcher.put<any>(url);
  };
}

const company = new CompanyService();
export default company;
