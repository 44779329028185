import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import commonService from "../services/CommonService";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import EntityFieldDialog from "./EntityFieldDialog";
import TyeaheadItem from "../components/TyeaheadItem";
import YesNoChip from "../components/YesNoChip";

function EntityTemplate(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.EntityTemplateOutputDto>({
    name: "",
    description: "",
  });

  const [origOrder, setOrigOrder] = useState<
    SysModels.EntityTemplateFieldOutputDto[]
  >([]);
  const current = useFetchHelper(
    async () => SysServices.http.entityTemplate.get(id || ""),
    "Entity Template"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.entityTemplate
        .update(id, {
          ...model,
          fields: (model.fields || []).map((f) => {
            return {
              ...f,
              entityFieldId: f.id,
              isFieldRequired: f.isFieldRequired,
            };
          }),
        })
        .then((data) => {
          toastStore.showToast("Entity Template Saved", "success");
          nav("/entity-templates");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Entity Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.entityTemplate
        .create({
          ...model,
          fields: (model.fields || []).map((f) => {
            return {
              ...f,
              entityFieldId: f.id,
              isFieldRequired: f.isFieldRequired,
            };
          }),
        })
        .then((data) => {
          toastStore.showToast("Entity Template Saved", "success");
          nav("/entity-templates");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Entity Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
      setOrigOrder(current.data.fields || []);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/entity-templates");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [itemFields, setItemFields] = useState([] as any[]);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const refFields = React.createRef<any>();
  const handleSearchFields = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemFields([]);
      setIsLoadingFields(false);
      return;
    }
    setIsLoadingFields(true);
    await SysServices.http.entityField
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemFields(options);
        setIsLoadingFields(false);
      })
      .catch((err) => {
        setItemFields([]);
        setIsLoadingFields(false);
      });
  };

  const [itemCategs, setItemCategs] = useState([] as any[]);
  const [isCategLoading, setIsCategLoading] = useState(false);
  const refCateg = React.createRef<any>();
  const handleSearchCategs = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemCategs([]);
      setIsCategLoading(false);
      return;
    }
    setIsCategLoading(true);
    await SysServices.http.templateCategory
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemCategs(options);
        setIsCategLoading(false);
      })
      .catch((err) => {
        setItemCategs([]);
        setIsCategLoading(false);
      });
  };

  const [dragging, setDragging] = useState<number>();
  const [dragOver, setDragOver] = useState<number>();

  useEffect(() => {
    if (
      model.id &&
      model.fields?.length &&
      model.fields?.length === origOrder.length
    ) {
      if (
        JSON.stringify(
          model.fields.map((x) => {
            return {
              sequence: x.sequence,
              id: x.id,
            };
          })
        ) !==
        JSON.stringify(
          origOrder.map((x) => {
            return {
              sequence: x.sequence,
              id: x.id,
            };
          })
        )
      ) {
        SysServices.http.entityTemplate
          .updateSequence(
            model.id,
            model.fields.map((f) => {
              return {
                entityFieldId: f.id,
                sequence: f.sequence,
              };
            })
          )
          .then((data) => {
            setOrigOrder(model.fields || []);
            toastStore.showToast("Sequence Updated", "success");
          })
          .catch((err) => {
            toastStore.showError("Failed Updating Sequence", err);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.fields]);

  const [delField, setDelField] = useState<number>();
  const [addField, setAddField] = useState(false);

  return (
    <div>
      <ConfirmDialog
        show={showDel}
        title="Delete Entity Template"
        message="Do you really want to delete this Entity Template?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.entityTemplate
              .delete(id || "")
              .then((data) => {
                toastStore.showToast("Entity Template Deleted", "success");
                nav("/entity-templates");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Entity Template", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={!!delField}
        title="Delete Field from Entity Template"
        message="Do you really want to delete this Field from this Entity Template?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            Promise.all([
              SysServices.http.entityTemplate.deleteField(
                model.id || "",
                delField || 0
              ),
              SysServices.http.entityTemplate.update(model.id || "", {
                ...model,
                fields: (model.fields || [])
                  .filter((f) => f.id !== delField)
                  .map((f, i) => {
                    return {
                      ...f,
                      entityFieldId: f.id,
                      sequence: i + 1,
                    };
                  }),
              }),
            ])
              .then((data) => {
                toastStore.showToast("Entity Field Deleted", "success");
                setModel({
                  ...model,
                  ...data[1],
                });
                current.setData(data[1]);
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Entity Field", err);
              })
              .finally(() => {
                setDeleting(false);
                setDelField(undefined);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {addField && (
        <EntityFieldDialog
          close={(added) => {
            setAddField(false);
          }}
        ></EntityFieldDialog>
      )}

      <h4>{id === "new" ? "Add" : "Edit"} Entity Template</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || id === "new") && (
        <div className="flex flex-row flex-wrap" style={{ gap: "20px" }}>
          <div className="mb-4" style={{ maxWidth: "400px", width: "100%" }}>
            <div className="pt-2">
              <div className="mb-2">
                <div className="mb-1">
                  <label className="required-label">Name</label>
                </div>
                <InputWithSimilaritySearch
                  placeholder="Template Name"
                  autoFocus={true}
                  currentId={model.id}
                  value={model.name || ""}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        name: val,
                      };
                    });
                  }}
                  request={() =>
                    SysServices.http.entityTemplate.typeAhead({
                      search: model.name || "",
                    })
                  }
                ></InputWithSimilaritySearch>
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Description</label>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={model.description}
                  rows={3}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        description: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-3">
                <div className="mb-1">
                  <label>Category</label>
                </div>
                {model.templateCategoryId ? (
                  <div className="flex flex-row alert alert-sm alert-secondary p-2">
                    <span className="flex-1 pe-2">
                      {model.templateCategoryName}
                    </span>
                    <span>
                      <i
                        title="Remove Category"
                        className="fa fa-times pointer"
                        onClick={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              templateCategoryName: null as any,
                              templateCategoryId: null as any,
                            };
                          });
                        }}
                      ></i>
                    </span>
                  </div>
                ) : (
                  <AsyncTypeahead
                    id="typeahead-search-category"
                    labelKey="name"
                    renderMenuItemChildren={(option, props, index) => (
                      <TyeaheadItem {...itemCategs[index]}></TyeaheadItem>
                    )}
                    onSearch={handleSearchCategs}
                    onChange={(data) => {
                      if (data.length > 0) {
                        setModel((prev) => {
                          return {
                            ...prev,
                            templateCategoryName: (data[0] as any).name,
                            templateCategoryId: (data[0] as any).id,
                          };
                        });
                        (refCateg.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isCategLoading}
                    options={itemCategs}
                    placeholder="Search Template Categories"
                    minLength={1}
                    delay={500}
                    useCache={false}
                    filterBy={() => true}
                    ref={refCateg}
                  />
                )}
              </div>
              <div className="mb-4">
                <SwitchButton
                  uncheckedLabel="User Template"
                  checked={model.userTemplate || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        userTemplate: val || false,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>

            <div className="pt-2">
              <button
                className="btn btn-sm btn-primary float-right"
                type="button"
                onClick={(e) => {
                  save();
                }}
                disabled={saving || deleting}
              >
                {saving ? "Saving..." : "Submit"}
              </button>
              <button
                className="btn btn-sm btn-secondary me-2 float-right"
                type="button"
                onClick={(e) => {
                  nav("/entity-templates");
                }}
                disabled={saving || deleting}
              >
                Cancel
              </button>

              {model.id && (
                <button
                  className="btn btn-sm btn-danger"
                  type="button"
                  onClick={(e) => {
                    setShowDel(true);
                  }}
                  disabled={!model.canBeUpdatedOrDeleted || saving || deleting}
                >
                  {deleting ? "Deleting..." : "Delete"}
                </button>
              )}
            </div>
          </div>
          <div className="flex-1">
            <div className="pt-2 mb-1">
              <label className="required-label">Fields</label>
            </div>

            <div className="flex flex-row col-sm-12 col-md-8 col-lg-6">
              <div className="flex-1">
                <AsyncTypeahead
                  id="typeahead-search-entity-fields"
                  labelKey="name"
                  onSearch={handleSearchFields}
                  renderMenuItemChildren={(option, props, index) => (
                    <TyeaheadItem {...itemFields[index]}></TyeaheadItem>
                  )}
                  onChange={(data) => {
                    if (data.length > 0) {
                      if (
                        model.fields?.find(
                          (f) => Number(f.id) === Number((data[0] as any).id)
                        )
                      ) {
                        toastStore.showToast(
                          "Entity Field aleady exist",
                          "warning"
                        );
                      } else {
                        setModel((prev) => {
                          return {
                            ...prev,
                            fields: [
                              ...(prev.fields || []),
                              {
                                id: Number((data[0] as any).id),
                                name: (data[0] as any).name,
                                sequence: (prev.fields?.length || 0) + 1,
                              },
                            ],
                          };
                        });
                        (refFields.current as any)?.clear();
                      }
                    }
                  }}
                  searchText={"Searching..."}
                  isLoading={isLoadingFields}
                  options={itemFields}
                  placeholder="Search Entity Fields"
                  minLength={1}
                  delay={500}
                  useCache={false}
                  filterBy={() => true}
                  ref={refFields}
                />
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setAddField(true);
                  }}
                  title="Add Entity Field"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <table className="table table-bordered table-hover mt-3">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Sequence</th>
                  <th>Name</th>
                  <th className="w-10 text-center">Required?</th>
                  <th style={{ width: "50px" }}></th>
                </tr>
              </thead>
              <tbody>
                {model.fields?.map((field) => (
                  <tr
                    key={`${field.id}-${field.sequence}`}
                    className={`${dragging === field.id ? "dragging" : ""} ${
                      dragOver === field.id
                        ? ((model.fields || []).find((x) => x.id === dragOver)
                            ?.sequence || 0) >
                          ((model.fields || []).find((x) => x.id === dragging)
                            ?.sequence || 0)
                          ? "dragover"
                          : "dragover-2"
                        : ""
                    }`}
                    style={{ cursor: "move" }}
                    draggable="true"
                    onDragStart={(e) => {
                      setDragging(field.id);
                    }}
                    onDragEnd={(e) => {
                      setDragging(undefined);
                      setDragOver(undefined);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      if (dragging === field.id) {
                        return;
                      }
                      setDragOver(field.id);
                    }}
                    onDrop={(e) => {
                      if (dragging) {
                        const adj =
                          (field.sequence || 0) >
                          ((model.fields || []).find((x) => x.id === dragging)
                            ?.sequence || 0)
                            ? 0.1
                            : -0.1;

                        setModel((prev) => {
                          return {
                            ...prev,
                            fields: (prev.fields || [])
                              .map((x) => {
                                if (x.id === dragging) {
                                  return {
                                    ...x,
                                    sequence: (field.sequence || 0) + adj,
                                  };
                                }
                                return x;
                              })
                              .sort(
                                commonService.sortByNumericProperty("sequence")
                              )
                              .map((x, i) => {
                                return {
                                  ...x,
                                  sequence: i + 1,
                                };
                              }),
                          };
                        });

                        setDragging(undefined);
                        setDragOver(undefined);
                      }
                    }}
                  >
                    <td>
                      <i className="fa fa-bars me-2"></i>
                      {field.sequence}
                    </td>
                    <td>{field.name}</td>
                    <td className="text-center">
                      {/* <YesNoChip yes={field.isFieldRequired} /> */}
                      <SwitchButton
                        checked={field.isFieldRequired || false}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              fields: (prev.fields || []).map((f) => {
                                if (f.id === field.id) {
                                  return {
                                    ...f,
                                    isFieldRequired: val,
                                  };
                                }
                                return f;
                              }),
                            };
                          });
                        }}
                      ></SwitchButton>
                    </td>
                    <td className="text-center">
                      <i
                        title="Delete Entity Field"
                        className="fa fa-trash text-danger pointer"
                        onClick={(e) => {
                          setDelField(field.id);
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default EntityTemplate;
