import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import SwitchButton from "../components/SwitchButton";

function UserType(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.UserTypeOutputDto>({
    name: "",
    description: "",
    canManageUsers: false,
  });

  const current = useFetchHelper(
    async () => SysServices.http.userType.get(id || ""),
    "User Type"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.userType
        .update(id as any, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("User Type Saved", "success");
          nav("/user-types");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving User Type", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.userType
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("User Type Saved", "success");
          nav("/user-types");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving User Type", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/user-types");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <div>
      <ConfirmDialog
        show={showDel}
        title="Delete User Type"
        message="Do you really want to delete this User Type?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.userType
              .delete(id as any)
              .then((data) => {
                toastStore.showToast("User Type Deleted", "success");
                nav("/user-types");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting User Type", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>{id === "new" ? "Add" : "Edit"} User Type</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || id === "new") && (
        <div style={{ maxWidth: "400px", width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">Name</label>
              </div>
              <InputWithSimilaritySearch
                placeholder="User Type"
                autoFocus={true}
                currentId={model.id}
                value={model.name || ""}
                onChange={(val) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      name: val,
                    };
                  });
                }}
                request={() =>
                  SysServices.http.userType.typeAhead({
                    search: model.name || "",
                  })
                }
              ></InputWithSimilaritySearch>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Description</label>
              </div>
              <textarea
                className="form-control"
                placeholder="Description"
                value={model.description}
                rows={3}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="mb-2">
              <div className="mb-2">
                <SwitchButton
                  uncheckedLabel="Can Manage Users?"
                  checked={model.canManageUsers || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        canManageUsers: val || false,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>
            <div className="mb-2">
              <div className="mb-4">
                <SwitchButton
                  uncheckedLabel="Allow To Get Any Reminder"
                  checked={model.canReceiveAnyReminder || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        canReceiveAnyReminder: val || false,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>
          </div>

          <div className="pt-2">
            <button
              className="btn btn-sm btn-primary float-right"
              type="button"
              onClick={(e) => {
                save();
              }}
              disabled={saving || deleting}
            >
              {saving ? "Saving..." : "Submit"}
            </button>
            <button
              className="btn btn-sm btn-secondary me-2 float-right"
              type="button"
              onClick={(e) => {
                nav("/user-types");
              }}
              disabled={saving || deleting}
            >
              Cancel
            </button>

            {model.id && (
              <button
                className="btn btn-sm btn-danger"
                type="button"
                onClick={(e) => {
                  setShowDel(true);
                }}
                disabled={!model.canBeDeleted || saving || deleting}
              >
                {deleting ? "Deleting..." : "Delete"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserType;
