import SysModels from "../../models";
import fetcher from "../Fetcher";

class TimeZoneService {
  get = async () => {
    const url = `/TimeZone`;
    return fetcher.get<SysModels.TypeAheadOutputDto[]>(url);
  };
}

const timeZone = new TimeZoneService();
export default timeZone;
