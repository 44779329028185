import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SysModels from "../models";
import SysServices from "../services";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import CommonSpinner from "../components/CommonSpinner";
import ConfirmDialog from "../components/ConfirmDialog";

function UserTemplate(props: any) {
  const { username } = useParams();
  const nav = useNavigate();
  const [model, setModel] = useState<
    SysModels.UserTemplateInputDto & { templateName?: string }
  >({});
  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    SysServices.http.user
      .saveUserTemplate(username || "", model)
      .then((rtn) => {
        toastStore.showToast("User Template Set", "success");
        nav("/user-templates");
      })
      .catch((err) => {
        toastStore.showError("Failed Setting User Template", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const [templates, setTemplates] = useState([] as any[]);
  const [isLoadingTemps, setIsLoadingTemps] = useState(false);
  const refTemp = React.createRef<any>();
  const handleSearchTemps = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setTemplates([]);
      setIsLoadingTemps(false);
      return;
    }
    setIsLoadingTemps(true);
    await SysServices.http.entityTemplate
      .typeAheadForUser({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setTemplates(options);
        setIsLoadingTemps(false);
      })
      .catch((err) => {
        setTemplates([]);
        setIsLoadingTemps(false);
      });
  };

  const current = useFetchHelper(
    () => SysServices.http.user.getUserReminderManager(username || ""),
    "User Template"
  );
  useEffect(() => {
    if (username) {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (current.status === FetchStatus.Complete) {
      setModel({
        templateId: current.data?.entityTemplateId,
        templateName: current.data?.entityTemplateName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [deleteTpl, setDeleteTpl] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <div>
      <h4>Set User Template</h4>
      {current.status === FetchStatus.InProgress && (
        <CommonSpinner></CommonSpinner>
      )}
      {deleting && (
        <CommonSpinner message="Removing Template..."></CommonSpinner>
      )}
      <ConfirmDialog
        show={deleteTpl}
        title="Remove Template"
        message="Do you really want to remove the template from this user?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes" && current.data?.userName) {
            setDeleting(true);
            SysServices.http.user
              .removeUserTemplate(current.data.userName)
              .then((data) => {
                toastStore.showToast("Template Removed from User", "success");
                setModel({
                  templateId: undefined,
                  templateName: undefined,
                });
              })
              .catch((err) => {
                toastStore.showError("Failed Removing Template", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setDeleteTpl(false);
        }}
      ></ConfirmDialog>
      <div
        style={{ maxWidth: "400px", width: "100%" }}
        className={
          current.status === FetchStatus.Complete ? "" : "display-none"
        }
      >
        <div className="pt-2">
          <div className="mb-2">
            <div className="mb-1">
              <label>Name</label>
            </div>
            <div className="alert alert-sm alert-secondary p-2 px-3">
              {current.data?.lastName}, {current.data?.firstName}
            </div>
          </div>
          <div className="mb-2">
            <div className="mb-1">
              <label>Username</label>
            </div>
            <div className="alert alert-sm alert-secondary p-2 px-3">
              {decodeURIComponent(username || "")}
            </div>
          </div>
          <div>
            <div className="mb-1">
              <label>Entity Template</label>
            </div>
            <div className="mb-2">
              <AsyncTypeahead
                id="typeahead-search-template"
                labelKey="name"
                renderMenuItemChildren={(option, props, index) => (
                  <TyeaheadItem {...templates[index]}></TyeaheadItem>
                )}
                onSearch={handleSearchTemps}
                onChange={(data) => {
                  if (data.length > 0) {
                    const item = data[0] as any;
                    setModel((prev) => {
                      return {
                        ...prev,
                        templateId: item.id,
                        templateName: item.name,
                      };
                    });
                    (refTemp.current as any)?.clear();
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoadingTemps}
                options={templates}
                placeholder="Search Entity Template"
                minLength={1}
                delay={500}
                useCache={false}
                filterBy={() => true}
                ref={refTemp}
                autoFocus={true}
              />
              {model.templateId && (
                <div
                  className={`alert alert-sm alert-${
                    model.templateId === current.data?.entityTemplateId
                      ? "secondary"
                      : "primary"
                  } p-2 px-3 mt-3 flex flex-row flex-center`}
                >
                  <div className="flex-1">{model.templateName}</div>
                  <div>
                    {!!current.data?.entityTemplateId && (
                      <i
                        className="fa fa-times pointer text-danger"
                        onClick={(e) => setDeleteTpl(true)}
                      ></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="pt-2">
          <button
            className="btn btn-sm btn-primary float-right"
            type="button"
            onClick={(e) => {
              save();
            }}
            disabled={
              saving || commonService.isNullOrEmpty(model.templateId || "")
            }
          >
            {saving ? "Saving..." : "Submit"}
          </button>
          <button
            className="btn btn-sm btn-secondary me-2 float-right"
            type="button"
            onClick={(e) => {
              nav("/user-templates");
            }}
            disabled={saving}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserTemplate;
