import SysModels from "../../models";
import fetcher from "../Fetcher";

class SystemErrorService {
  list = async (
    page: number,
    pageSize: number,
    data: SysModels.ErrorMaintenanceSearchInputDto
  ) => {
    const url = `/SystemError/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.ErrorMaintenanceGridOutputDto>(url, data);
  };

  delete = async (error: SysModels.ErrorMaintenanceDeleteInputDto) => {
    const url = `/SystemError`;
    return fetcher.delete<any>(url, error);
  };

  deleteAll = async () => {
    const url = `/SystemError/DeleteAll`;
    return fetcher.delete<any>(url);
  };
}

const systemError = new SystemErrorService();
export default systemError;
