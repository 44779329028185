import React, { useEffect, useState } from "react";
import FormModal from "../components/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import SwitchButton from "../components/SwitchButton";

function EntityFieldDialog(props: { close: (added?: boolean) => void }) {
  const [model, setModel] = useState<SysModels.EntityFieldOutputDto>({
    fieldType: SysModels.WF1FieldType.Date,
    name: "",
    description: "",
  });
  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    await SysServices.http.entityField
      .create({
        ...model,
      })
      .then((data) => {
        toastStore.showToast("Entity Field Saved", "success");
        props.close(true);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Entity Field", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("WF1FieldType"),
    "Field Types"
  );

  useEffect(() => {
    types.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="Add Entity Field"
      size="md"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        save();
      }}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
    >
      <div>
        <div style={{ width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label>Field Type</label>
              </div>
              <select
                className="form-control"
                value={model.fieldType}
                disabled={!!model.id && !model.canBeUpdateOrDeleted}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      fieldType: commonService.isNullOrEmpty(e.target.value)
                        ? 0
                        : Number(e.target.value),
                    };
                  });
                }}
              >
                {types.status === FetchStatus.InProgress && (
                  <option>Loading...</option>
                )}
                {types.data?.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">Name</label>
              </div>
              <InputWithSimilaritySearch
                placeholder="Field Name"
                autoFocus={true}
                currentId={model.id}
                value={model.name || ""}
                onChange={(val) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      name: val,
                    };
                  });
                }}
                request={() =>
                  SysServices.http.entityField.typeAhead({
                    search: model.name || "",
                  })
                }
              ></InputWithSimilaritySearch>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Description</label>
              </div>
              <textarea
                className="form-control"
                placeholder="Description"
                value={model.description}
                rows={3}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <div className="mb-2">
                <SwitchButton
                  uncheckedLabel="Is Required?"
                  checked={model.isFieldRequired || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        isFieldRequired: val || false,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default EntityFieldDialog;
