import React, { useEffect, useState } from "react";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";
import SysModels from "../models";

function ForgotPassword(props: any) {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [model, setModel] = useState<SysModels.ForgotPasswordDto>({
    userName: "",
  });
  const allowed = () => {
    return !commonService.isNullOrEmpty(model.userName || "");
  };

  useEffect(() => {
    commonService.focusInput("login_password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="login-page"
      //style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="Splash" src="/webf1-center.png" />
        <h4 className="text-left pt-4 mb-0 text-primary">Forgot Password?</h4>
        <form
          className="pt-4"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (allowed()) {
              setInProgress(true);
              SysServices.http.myAccount
                .forgotPassword(model)
                .then((data: any) => {
                  toastStore.showToast(
                    "Password reset link has been sent to your email.",
                    "success"
                  );
                  navigate("/");
                })
                .catch((err: any) => {
                  toastStore.showError("Request Failed", err);
                  setInProgress(false);
                });
            }
          }}
        >
          <input
            id="login_password"
            type="input"
            name="email"
            autoComplete="new-password"
            placeholder="Email"
            className="form-control mb-3"
            value={model.userName}
            onChange={(e) => {
              setModel((p) => {
                return {
                  ...p,
                  userName: e.target.value,
                };
              });
            }}
            disabled={inProgress}
          />
          <div className="flex flex-center">
            <div></div>
            <div className="flex-1 text-right">
              <button
                className="btn btn-secondary me-2"
                type="button"
                disabled={inProgress}
                onClick={(e) => {
                  navigate("/");
                }}
              >
                <span className="px-3">Cancel</span>
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={inProgress || !allowed()}
              >
                <span className="px-3">
                  {inProgress ? "Sending Request..." : "Send Request"}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
