import SysModels from "../../models";
import fetcher from "../Fetcher";

class MyAccountService {
  login = async (data: SysModels.UserLoginDto) => {
    const url = `/MyAccount/Login`;
    return fetcher.post<any>(url, data, { notoken: true });
  };

  loginDifferentCompany = async (companyId: string) => {
    const url = `/MyAccount/LoginDifferentCompany/${companyId}`;
    return fetcher.post<SysModels.TokenDto>(url);
  };

  revertBackToParent = async () => {
    const url = `/MyAccount/RevertBackToParent`;
    return fetcher.post<SysModels.TokenDto>(url);
  };

  logout = async (sessionId: string) => {
    const url = `/MyAccount/Logout/${sessionId}`;
    return fetcher.get<any>(url);
  };

  changePassword = async (data: SysModels.ChangePasswordRequestDto) => {
    const url = `/MyAccount/ChangePassword`;
    return fetcher.post<any>(url, data);
  };

  forgotPassword = async (data: SysModels.ForgotPasswordDto) => {
    const url = `/MyAccount/ForgotPassword`;
    return fetcher.post<any>(url, data, { notoken: true });
  };

  resetPassword = async (data: SysModels.ResetPasswordDto) => {
    const url = `/MyAccount/ResetPassword`;
    return fetcher.post<any>(url, data, { notoken: true });
  };
}

const myAccount = new MyAccountService();
export default myAccount;
