import React from "react";

function YesNoChip(props: { yes?: boolean }) {
  return (
    <span className={`px-2 ${props.yes ? "chip-green" : "chip-red"}`}>
      {props.yes ? "Yes" : "No"}
    </span>
  );
}

export default YesNoChip;
