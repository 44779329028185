import React from "react";
import { useActiveRole } from "../stores/SystemStore";

function Dashboard(props: any) {
  const activeRole = useActiveRole();

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col col-sm-12 col-md-6">{activeRole} Dashboard</div>
        <div className="col col-sm-12 col-md-6"> </div>
      </div>
    </div>
  );
}

export default Dashboard;
