import SysModels from "../../models";
import fetcher from "../Fetcher";

class ConfigSettingService {
  get = async (area: string, section: string) => {
    const url = `/ConfigSetting/${area}/${section}`;
    return fetcher.get<SysModels.ConfigSettingDto>(url);
  };

  delete = async (area: string, section: string) => {
    const url = `/ConfigSetting/${area}/${section}`;
    return fetcher.delete<any>(url);
  };

  all = async () => {
    const url = `/ConfigSetting/All`;
    return fetcher.get<SysModels.ConfigSettingDto[]>(url);
  };

  save = async (data: SysModels.ConfigSettingDto) => {
    const url = `/ConfigSetting/Save`;
    return fetcher.post<boolean>(url, data);
  };
}

const configSetting = new ConfigSettingService();
export default configSetting;
