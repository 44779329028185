import React from "react";
import { Button, Modal } from "react-bootstrap";

export interface IConfirmButton {
  label: string;
  value: any;
  variant: "primary" | "secondary" | "danger" | "warning" | "success";
  size?: "sm";
  disabled?: boolean;
}
export interface IConfirmDialogProps {
  message: string;
  title?: string;
  show?: boolean;
  buttons?: "okcancel" | "yesno" | "okonly" | IConfirmButton[];
  done: (response?: any) => void;
}

function ConfirmDialog(props: IConfirmDialogProps) {
  const handleClose = () => {
    props.done();
  };

  return (
    <>
      {props.show && (
        <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>{props.title || "Confirmation"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: props.message }}></div>
          </Modal.Body>

          {props?.buttons &&
            props.buttons !== "yesno" &&
            props.buttons !== "okcancel" &&
            props.buttons !== "okonly" && (
              <Modal.Footer>
                {(props.buttons as IConfirmButton[]).map((b, i) => {
                  return (
                    <Button
                      key={i}
                      type="button"
                      variant={b.variant}
                      onClick={() => {
                        props.done(b.value);
                      }}
                      size={b.size}
                      disabled={b.disabled || false}
                    >
                      {b.label}
                    </Button>
                  );
                })}
              </Modal.Footer>
            )}

          {props.buttons === "yesno" && (
            <Modal.Footer>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  props.done("no");
                }}
              >
                No
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  props.done("yes");
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          )}

          {(!props.buttons || props.buttons === "okcancel") && (
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  props.done("cancel");
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  props.done("ok");
                }}
              >
                OK
              </Button>
            </Modal.Footer>
          )}

          {(!props.buttons || props.buttons === "okonly") && (
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  props.done("ok");
                }}
              >
                OK
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
}

export default ConfirmDialog;
