import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityService {
  get = async (id: string) => {
    const url = `/Entity/${id}`;
    return fetcher.get<SysModels.EntityOutputDto>(url);
  };

  getEntityReminderManager = async (id: string) => {
    const url = `/Entity/EntityReminderManager/${id}`;
    return fetcher.get<SysModels.EntityOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.EntitySearchInputDto
  ) => {
    const url = `/Entity/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityGridOutputDto>(url, model);
  };

  listReminderManager = async (
    page: number,
    pageSize: number,
    model: SysModels.EntitySearchInputDto
  ) => {
    const url = `/Entity/ListReminderManager/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityGridOutputDto>(url, model);
  };

  create = async (model: SysModels.EntityInputDto) => {
    const url = `/Entity`;
    return fetcher.post<string>(url, model);
  };

  update = async (id: string, model: SysModels.EntityUpdateDto) => {
    const url = `/Entity/${id}`;
    return fetcher.put<SysModels.EntityOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/Entity/${id}`;
    return fetcher.delete<any>(url);
  };
}

const entity = new EntityService();
export default entity;
