import React from "react";
import { Spinner } from "react-bootstrap";

function CommonSpinner({
  color,
  overlay,
  message,
}: {
  color?: string;
  overlay?: boolean;
  message?: string;
}) {
  const style = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "rgba(255,255,255,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
  } as React.CSSProperties;

  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px",
        ...(overlay ? style : {}),
      }}
    >
      <Spinner animation="border" variant={color || "success"}></Spinner>
      {!!message && <div className="mt-2 p-1 text-primary">{message}</div>}
    </div>
  );
}

export default CommonSpinner;
