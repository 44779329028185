import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import SideNav from "./SideNav";
import SysModels from "../models";
import { reaction } from "mobx";
import systemStore, { useLoginStatus } from "../stores/SystemStore";
import SysServices from "../services";
import commonService from "../services/CommonService";
import SystemBanner from "../components/SystemBanner";

function Layout({ children }: any) {
  const [showLeftPanel, setShowLeftPanel] = useState(false);
  const isSmallScreen = () => window.innerWidth < 1400;

  const [dismissed, setDismissed] = useState(false);
  const [tmoSystemMessage, setTmoSystemMessage] = useState(0);
  const [message, setMessage] =
    useState<SysModels.GlobalSiteMessageDisplayDto>();

  const loginStatus = useLoginStatus();

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.tmoSystemMessage,
      (val, prevVal, r) => {
        setTmoSystemMessage(val);
      }
    );

    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (loginStatus.canAccessWebsite && !loginStatus.mustDoSomething) {
      SysServices.http.globalSiteMessage
        .getDisplay()
        .then((data) => {
          setMessage(data);
          systemStore.setSystemMessageVisible(
            !commonService.isNullOrEmpty(data.message || "")
          );
        })
        .catch((error) => {});
    } else {
      systemStore.setSystemMessageVisible(false);
    }
  }, [
    loginStatus.canAccessWebsite,
    loginStatus.mustDoSomething,
    tmoSystemMessage,
  ]);

  return (
    <div className="layout">
      <div className="layout-top">
        <NavBar
          toggle={() => {
            setShowLeftPanel(!showLeftPanel);
          }}
        ></NavBar>
      </div>
      <div className="layout-bottom">
        <div
          className={`overlay ${
            showLeftPanel && isSmallScreen() ? "show" : ""
          }`}
          onClick={() => setShowLeftPanel(false)}
        ></div>
        <div
          className={`left-navbar ${showLeftPanel ? "show" : ""}`}
          onMouseOver={() => {
            if (isSmallScreen()) {
              setShowLeftPanel(true);
            }
          }}
          onMouseLeave={() => setShowLeftPanel(false)}
        >
          <SideNav
            toggle={() => {
              setShowLeftPanel(!showLeftPanel);
            }}
          ></SideNav>
        </div>
        <div className="main-container">
          {!dismissed &&
            !commonService.isNullOrEmpty(message?.message || "") && (
              <SystemBanner
                message={message?.message || ""}
                error={message?.severity === SysModels.ErrorSeverityEnum.Error}
                warning={
                  message?.severity === SysModels.ErrorSeverityEnum.Warning
                }
                onDismiss={() => setDismissed(true)}
              />
            )}

          <div className="main-body">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
