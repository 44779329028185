import React from "react";

function TyeaheadItem(item: { name: string; description: string }) {
  return (
    <div className="custom-typeahead-item-menu">
      <div>{item.name}</div>
      {item.description && (
        <small>
          <i>{item.description}</i>
        </small>
      )}
    </div>
  );
}

export default TyeaheadItem;
