import SysModels from "../../models";
import fetcher from "../Fetcher";

class UserTypeService {
  get = async (id: string) => {
    const url = `/UserType/${id}`;
    return fetcher.get<SysModels.UserTypeOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.UserTypeSearchInputDto
  ) => {
    const url = `/UserType/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.UserTypeGridOutputDto>(url, model);
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/UserType/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.UserTypeInputDto) => {
    const url = `/UserType`;
    return fetcher.post<any>(url, model);
  };

  update = async (id: number, model: SysModels.UserTypeInputDto) => {
    const url = `/UserType/${id}`;
    return fetcher.put<any>(url, model);
  };

  delete = async (id: number) => {
    const url = `/UserType/${id}`;
    return fetcher.delete<any>(url);
  };
}

const userType = new UserTypeService();
export default userType;
