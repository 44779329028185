import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import DateTimePicker from "../components/DateTimePicker";
import DocEditor from "../components/DocEditor";

function ReleaseNote(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.ReleaseOutputDto>({});

  const current = useFetchHelper(
    async () => SysServices.http.release.get(id || ""),
    "Release Note"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.release
        .update(id as any, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Release Note Saved", "success");
          nav("/release-notes-maint");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Release Note", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.release
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Release Note Saved", "success");
          nav("/release-notes-maint");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Release Note", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/release-notes-maint");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <div className="h-100 flex flex-column">
      <ConfirmDialog
        show={showDel}
        title="Delete Release Note"
        message="Do you really want to delete this Release Note?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.release
              .delete(id as any)
              .then((data) => {
                toastStore.showToast("Release Note Deleted", "success");
                nav("/release-notes-maint");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Release Note", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>{id === "new" ? "Add" : "Edit"} Release Note</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || id === "new") && (
        <div className="flex-1 flex flex-column" style={{ width: "100%" }}>
          <div className="pt-2">
            <div style={{ maxWidth: "400px" }}>
              <div className="mb-2">
                <div className="mb-1">
                  <label className="required-label">Version</label>
                </div>
                <input
                  className="form-control required"
                  placeholder="Version"
                  autoFocus={true}
                  value={model.versionNumber || ""}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        versionNumber: val.target.value || "",
                      };
                    });
                  }}
                ></input>
              </div>
              <div className="mb-2">
                <div className="mb-2">
                  <label className="required-label">Release Date</label>
                </div>
                <DateTimePicker
                  dateOnly={true}
                  data={model.releaseDate}
                  onChange={(data) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        releaseDate: data,
                      };
                    });
                  }}
                  disabled={props.viewOnly}
                />
              </div>
            </div>
            <div className="py-2">
              <div className="mb-2">
                <SwitchButton
                  uncheckedLabel="Public"
                  checked={model.public || false}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        public: val || false,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>
          </div>
          <div className="mb-3 flex-1">
            <div className="mb-1">
              <label className="required-label">Description</label>
            </div>
            <div className="document-body">
              <div>
                <DocEditor
                  key={model.id || "new"}
                  content={model.description || ""}
                  onChange={(val) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        description: val,
                      };
                    });
                  }}
                  onSave={() => {
                    save();
                  }}
                  onDialogOpen={(open) => {}}
                ></DocEditor>
              </div>
            </div>
            {/* <textarea
                className="form-control"
                placeholder="Description"
                value={model.description}
                rows={3}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              /> */}
          </div>
          <div className="pt-2">
            <button
              className="btn btn-sm btn-primary float-right"
              type="button"
              onClick={(e) => {
                save();
              }}
              disabled={saving || deleting}
            >
              {saving ? "Saving..." : "Submit"}
            </button>
            <button
              className="btn btn-sm btn-secondary me-2 float-right"
              type="button"
              onClick={(e) => {
                nav("/release-notes-maint");
              }}
              disabled={saving || deleting}
            >
              Cancel
            </button>

            {id !== "new" && (
              <button
                className="btn btn-sm btn-danger"
                type="button"
                onClick={(e) => {
                  setShowDel(true);
                }}
                disabled={saving || deleting}
              >
                {deleting ? "Deleting..." : "Delete"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReleaseNote;
