import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavAnchor(props: {
  link: string;
  label: string;
  icon?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
}) {
  const nav = useNavigate();
  const loc = useLocation();

  const isActive = useMemo(() => {
    return (
      `${loc.pathname || ""}`.split("/")[1] ===
      `${props.link || ""}`.split("/")[1]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.pathname, loc.state]);

  return (
    <>
      <a
        className={
          props.disabled
            ? ""
            : `${!!(isActive || props.active === true) ? "active" : ""}`
        }
        href={props.link}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          nav(props.link);
          props.onClick && props.onClick();
        }}
      >
        {!!props.icon && <i className={`${props.icon} me-2`}></i>}
        <span>{props.label}</span>
      </a>
    </>
  );
}

export default NavAnchor;
