import React, { useEffect } from "react";
import NavAnchor from "../components/NavAnchor";
import menuActions from "./MenuActions";
import systemStore, {
  useActiveRole,
  useLoginStatus,
} from "../stores/SystemStore";
import commonService from "../services/CommonService";
import { useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import { useNavigate } from "react-router-dom";

function SideNav(props: any) {
  const nav = useNavigate();
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("RolesEnum"),
    "Roles"
  );

  useEffect(() => {
    roles.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="side-nav-panel">
      {loginStatus.companySwitched && (
        <>
          <div className="mt-2" title={loginStatus.companyName}>
            <strong
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                color: "#555",
              }}
            >
              {loginStatus.companyName}
            </strong>
          </div>
          <div
            className="pointer text-primary bg-hover2"
            style={{
              backgroundColor: "#efefef",
            }}
            onClick={(e) => {
              systemStore.setSwitchingCompany(true);
              SysServices.http.myAccount
                .revertBackToParent()
                .then((data) => {
                  SysServices.http.fetcher.storeToken(data);
                  setTimeout(() => {
                    systemStore.setActiveRole("SaaSAdmin");
                    nav("/companies");
                  }, 100);
                })
                .catch((err) => {
                  toastStore.showError("Failed Switching Back", err);
                })
                .finally(() => {
                  systemStore.setSwitchingCompany(false);
                });
            }}
          >
            <i className="fa fa-sign-out fa-2x me-3"></i>
            <strong>Back to SaaS Admin</strong>
          </div>
        </>
      )}
      <div className="mt-2">
        <strong>ROLES</strong>
      </div>
      <ul className="navbar-nav">
        <li className="navbar-nav">
          <NavAnchor
            link="/dashboard"
            icon="fa fa-home"
            label="Dashboard"
            disabled={true}
          ></NavAnchor>
        </li>
        {roles.data
          ?.filter((x) => loginStatus.hasRole(x.label))
          ?.map((x) => (
            <li className="navbar-nav px-3 pe-0" key={x.value}>
              <NavAnchor
                active={commonService.isSameRoleName(activeRole, x.label)}
                link={
                  ma.actions.find(
                    (a) =>
                      a.dashboard &&
                      commonService.isSameRoleName(a.roles[0], x.label)
                  )?.url || "/dashboard"
                }
                icon="fa fa-minus"
                label={x.label || ""}
                // onClick={() => {
                //   systemStore.setActiveRole(
                //     `${x.label || ""}`.replaceAll(" ", "") as USER_ROLES
                //   );
                // }}
              ></NavAnchor>
            </li>
          ))}
        <hr />
      </ul>
      <div>
        <strong>
          {
            roles.data?.find((r) =>
              commonService.isSameRoleName(r.label, activeRole)
            )?.label
          }
        </strong>
      </div>
      <ul className="navbar-nav">
        {ma.actions
          .sort(commonService.sortByStringProperty("label"))
          .filter((action) => activeRole && action.roles.includes(activeRole))
          .map((action) => {
            if (
              !action.hidden &&
              (action.roles?.length === 0 ||
                action.roles?.find((r) => loginStatus.hasRole(r)))
            ) {
              return (
                <li
                  className="navbar-nav"
                  key={action.url}
                  title={action.label}
                >
                  <NavAnchor
                    link={action.url}
                    icon={`fa ${action.icon}`}
                    label={action.label}
                  ></NavAnchor>
                </li>
              );
            }
            return <React.Fragment key={action.url} />;
          })}
      </ul>
    </div>
  );
}

export default SideNav;
