import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { Dropdown } from "react-bootstrap";
import {
  BOOLEAN_FILTERS,
  GetDisplayForBooleanFilter,
} from "../services/CommonService";
import YesNoChip from "../components/YesNoChip";
import systemStore, { useLastPageFilters } from "../stores/SystemStore";

function Companies(props: any) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [isActive, setIsActive] = useState<boolean | undefined>(true);
  const [isArchived, setIsArchived] = useState<boolean | undefined>(false);

  const list = useFetchHelper(
    async () =>
      SysServices.http.company.list(paging.page, paging.pageSize, {
        search: search.used,
        active: isActive,
        archived: isArchived,
      }),
    "Companies"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
      others: { active: true, archived: false },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
        setIsActive(filters.others?.active);
        setIsArchived(filters.others?.archived);
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: { active: isActive, archived: isArchived },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, isActive, isArchived, pageFilters.ready]);

  return (
    <div>
      <div className="mb-3">
        <h4>Companies</h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-0">
              <button
                className="btn btn-primary no-wrap"
                type="button"
                onClick={(e) => {
                  nav("/companies/new");
                }}
              >
                Add Company
              </button>
            </div>
            <div className="flex-1 flex flex-center gap-10">
              <div className="flex-0">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    disabled={list.status === FetchStatus.InProgress}
                    className="no-wrap"
                  >
                    <span className="me-2">
                      Active: {GetDisplayForBooleanFilter(isActive)}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    {BOOLEAN_FILTERS.map((opt) => (
                      <Dropdown.Item
                        key={opt}
                        active={opt === GetDisplayForBooleanFilter(isActive)}
                        onClick={(e) => {
                          if (opt === "Yes") setIsActive(true);
                          if (opt === "No") setIsActive(false);
                          if (opt === "All") setIsActive(undefined);
                        }}
                      >
                        {opt}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="flex-0">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    disabled={list.status === FetchStatus.InProgress}
                    className="no-wrap"
                  >
                    <span className="me-2">
                      Archived: {GetDisplayForBooleanFilter(isArchived)}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    {BOOLEAN_FILTERS.map((opt) => (
                      <Dropdown.Item
                        key={opt}
                        active={opt === GetDisplayForBooleanFilter(isArchived)}
                        onClick={(e) => {
                          if (opt === "Yes") setIsArchived(true);
                          if (opt === "No") setIsArchived(false);
                          if (opt === "All") setIsArchived(undefined);
                        }}
                      >
                        {opt}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th className="w-50">Address</th>
                    <th className="text-center">Active</th>
                    <th className="text-center">Archived</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={4}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.companyDetailItemDtos?.map((company) => (
                    <tr
                      key={company.id}
                      className="pointer"
                      onClick={(e) => {
                        nav(`/companies/${company.id}`);
                      }}
                    >
                      <td>
                        <div className="flex flex-row">
                          <div className="flex-1">{company.companyName}</div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-sm py-0"
                              title="Switch"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                systemStore.setSwitchCompany(
                                  true,
                                  company.id,
                                  company.companyName
                                );
                              }}
                            >
                              <i className="fa fa-exchange text-primary"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        {[
                          company.companyAddress?.address1,
                          company.companyAddress?.address2,
                          company.companyAddress?.city,
                          company.companyAddress?.state,
                          company.companyAddress?.postalCode,
                          company.companyAddress?.country,
                        ]
                          .filter((f) => !!f)
                          .join(", ")}
                      </td>
                      <td className="text-center">
                        <YesNoChip yes={company.active} />
                      </td>
                      <td className="text-center">
                        <YesNoChip yes={company.archived} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="Companies"
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Companies;
