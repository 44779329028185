import React from "react";

function CellOneLineWordBreak(props: { text?: string }) {
  return (
    <div className="cell-word-break">
      <div>{props.text || ""}</div>
      <span>{props.text || ""}</span>
    </div>
  );
}

export default CellOneLineWordBreak;
