import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";
import SysServices from "../services";

function LoggedOut(props: any) {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(img/bg-login.jpg)` }}
    >
      <div>
        <img alt="WebF1 Splash" src="/webf1-center.png" />
        <div>You've been logged out</div>
        <Button
          className="mt-3"
          variant="primary"
          onClick={() => {
            SysServices.http.fetcher.clearToken();
            navigate("/");
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default LoggedOut;
