import React, { useEffect, useState } from "react";
import { Pagination as BsPagination } from "react-bootstrap";

export interface IPagination {
  size?: "sm" | "lg";
  length: number;
  page: number;
  pageSize: number;
  maxPagesToShow?: number;
  pageChange: (page: number, pageSize: number) => void;
  showingOfWhatLabel?: string;
  disabled?: boolean;
  sizes?: number[];
  totalRows?: number;
  noJumpToPage?: boolean;
}

export function usePaging<T>(page: number, pageSize: number, ext?: T) {
  return useState({ page, pageSize, ...(ext || ({} as Partial<T>)) });
}

function Pagination(props: IPagination) {
  let active = props?.page || 1;
  let items = [];
  const totalPages = Math.ceil(props.length / props.pageSize);
  let maxPages = props?.maxPagesToShow || 5;

  if (active > totalPages) {
    active = totalPages;
  }

  const maxPageFloor = Math.floor(maxPages / 2);
  let firstVisiblePage = active - maxPageFloor;
  if (firstVisiblePage < maxPageFloor) {
    firstVisiblePage = 1;
  }
  if (firstVisiblePage + maxPages > totalPages) {
    firstVisiblePage = totalPages - maxPages + 1;
  }

  if (maxPages > totalPages) {
    maxPages = totalPages;
    firstVisiblePage = 1;
  }

  const [manualPage, setManualPage] = useState<any>(active);
  const isValidNumber = () => {
    const str = `${manualPage || ""}`.trim();
    if (str === "") {
      return true;
    }
    if (!/^\d+$/.test(`${str}`)) {
      return false;
    }
    if (Number(manualPage) > totalPages) {
      return false;
    }
    if (Number(manualPage) < 1) {
      return false;
    }
    return true;
  };

  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  for (
    let number = firstVisiblePage;
    number <= firstVisiblePage + maxPages - 1;
    number++
  ) {
    const showInput = number === active && totalPages > maxPages;
    items.push(
      showInput ? (
        <li
          key={number}
          className={`page-item ${
            showTooltip1 || showTooltip2 ? "paging-hit-enter" : ""
          }`}
        >
          <input
            type="text"
            className={`text-center px-0 form-control form-control-${
              props.size || "sm"
            } ${isValidNumber() ? "" : "error"}`}
            style={{ width: "50px", height: "100%" }}
            placeholder={`${number}`}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidNumber() && Number(manualPage)) {
                props.pageChange(Number(manualPage), props.pageSize);
              }
            }}
            onChange={(e) => {
              setManualPage(e.target.value);
            }}
            onFocus={(e) => setShowTooltip1(true)}
            onBlur={(e) => setShowTooltip1(false)}
            onMouseOver={(e) => setShowTooltip2(true)}
            onMouseLeave={(e) => setShowTooltip2(false)}
          ></input>
        </li>
      ) : (
        <BsPagination.Item
          key={number}
          active={number === active}
          onClick={() => props.pageChange(number, props.pageSize)}
          disabled={number === active || props.disabled}
        >
          {number}
        </BsPagination.Item>
      )
    );
  }

  useEffect(() => {
    setManualPage("");
  }, [props?.page]);

  const getPagingMessage = () => {
    if (!props.length || props.noJumpToPage) {
      return "";
    }
    const from = props.pageSize * (props.page - 1) + 1;
    const to = props.pageSize * props.page;

    let ttlMsg = "";
    if (props.totalRows && props.totalRows > 0) {
      ttlMsg = `. Total of ${Number(props.totalRows).toLocaleString()} rows`;
    }
    const $to = to > props.length ? props.length : to;
    return `Showing ${from.toLocaleString()} to ${$to.toLocaleString()} of ${
      props.noJumpToPage ? $to : props.length.toLocaleString()
    } ${props?.showingOfWhatLabel || ""}${ttlMsg}`;
  };

  return (
    <>
      <div
        className="pt-3 flex flex-row flex-wrap"
        style={{ gap: "15px", alignItems: "center" }}
      >
        {props?.sizes && props.sizes.length > 0 && (
          <div className="flex flex-row" style={{ alignItems: "center" }}>
            <span className="pe-2">Show</span>
            <div className="form-group" style={{ margin: 0 }}>
              <select
                className="form-control form-control-sm"
                value={props.pageSize}
                onChange={(event) =>
                  props.pageChange(1, Number(event.target.value))
                }
                disabled={props.disabled || false}
              >
                {(props?.sizes || [10, 20, 50, 100]).map((size) => (
                  <option value={size} key={size}>
                    {size.toLocaleString()}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="flex-1 nowrap" style={{ textAlign: "left" }}>
          <span>{getPagingMessage()}</span>
        </div>

        {props.noJumpToPage && (
          <div>
            <BsPagination size={props.size} style={{ margin: 0 }}>
              <BsPagination.Prev
                onClick={() => props.pageChange(props.page - 1, props.pageSize)}
                disabled={props.page === 1 || props.disabled}
              />
              <BsPagination.Next
                onClick={() => props.pageChange(props.page + 1, props.pageSize)}
                disabled={props.page === totalPages || props.disabled}
              />
            </BsPagination>
          </div>
        )}
        {!!props?.length && !props.noJumpToPage && (
          <div>
            <BsPagination size={props.size} style={{ margin: 0 }}>
              {totalPages > maxPages && (
                <>
                  <BsPagination.First
                    onClick={() => props.pageChange(1, props.pageSize)}
                    disabled={props.page === 1 || props.disabled}
                  />
                  <BsPagination.Prev
                    onClick={() =>
                      props.pageChange(props.page - 1, props.pageSize)
                    }
                    disabled={props.page === 1 || props.disabled}
                  />
                </>
              )}
              {items}
              {totalPages > maxPages && (
                <>
                  <BsPagination.Next
                    onClick={() =>
                      props.pageChange(props.page + 1, props.pageSize)
                    }
                    disabled={props.page === totalPages || props.disabled}
                  />
                  <BsPagination.Last
                    onClick={() => props.pageChange(totalPages, props.pageSize)}
                    disabled={props.page === totalPages || props.disabled}
                  />
                </>
              )}
            </BsPagination>
          </div>
        )}
      </div>
    </>
  );
}

export default Pagination;
