import SysModels from "../../models";
import fetcher from "../Fetcher";

class UserSettingService {
  get = async (settingId: string) => {
    const url = `/UserSetting/${settingId}`;
    return fetcher.get<SysModels.UserSettingSettingOutputDto>(url);
  };

  save = async (
    settingId: string,
    data: SysModels.UserSettingSettingInputDto
  ) => {
    const url = `/UserSetting/${settingId}`;
    return fetcher.post<SysModels.UserSettingSettingOutputDto>(url, data);
  };

  getLastDashboard() {
    return this.get("LastDashboard");
  }

  saveLastDashboard(dashboard: string) {
    return this.save("LastDashboard", {
      setting: dashboard,
    });
  }
}

const userSetting = new UserSettingService();
export default userSetting;
