import React from "react";
import { useSiteDown } from "../stores/SystemStore";
import commonService from "../services/CommonService";

function SiteDownPage(props: any) {
  const siteDown = useSiteDown();

  return (
    <div id="site-down">
      <div>
        <img alt="Splash" src="webf1-center.png" />
        <div>
          <div>
            <i className="fa fa-warning fa-2x text-danger"></i>
            <h5
              className="pt-2 text-danger"
              dangerouslySetInnerHTML={{
                __html: siteDown?.message || "Loading...",
              }}
            ></h5>
            <div>Until {commonService.toLocalDate(siteDown?.to, "full")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteDownPage;
