import React from "react";
function SystemBanner({
  message,
  error,
  warning,
  onDismiss,
}: {
  message: string;
  error?: boolean;
  warning?: boolean;
  onDismiss?: () => void;
}) {
  const MessageElement = () => {
    return <span dangerouslySetInnerHTML={{ __html: message }}></span>;
  };

  return (
    <div
      id="system-banner"
      className={`system-banner hide-on-print ${
        error ? "system-banner-error" : ""
      } ${warning ? "system-banner-warning" : ""}`}
    >
      <div>
        <i className="fa fa-bullhorn"></i>
      </div>
      <div className="fle flex-row flex-1">
        <div className="flex-1 pe-4">
          <MessageElement></MessageElement>
        </div>
        <div>
          <i
            className="fa fa-times pointer"
            title="Dismiss"
            onClick={() => {
              onDismiss && onDismiss();
            }}
          ></i>
        </div>
      </div>
    </div>
  );
}

export default SystemBanner;
