import React, { useEffect, useState } from "react";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/CommonSpinner";
import systemStore from "../stores/SystemStore";

function ChangePassword(props: { must?: boolean }) {
  const navigate = useNavigate();
  const [pwData, setPwData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [inProgress, setInProgress] = useState(false);

  const allowSave = () => {
    return (
      !commonService.isNullOrEmpty(pwData.currentPassword) &&
      !commonService.isNullOrEmpty(pwData.newPassword) &&
      !commonService.isNullOrEmpty(pwData.confirmPassword) &&
      passwordMatch()
    );
  };

  const passwordMatch = () => {
    if (
      !commonService.isNullOrEmpty(pwData.newPassword) &&
      !commonService.isNullOrEmpty(pwData.confirmPassword)
    ) {
      return pwData.newPassword === pwData.confirmPassword;
    }
    return true;
  };

  useEffect(() => {
    commonService.focusInput("login_password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {inProgress && (
        <CommonSpinner
          overlay={true}
          message="Changing Password..."
        ></CommonSpinner>
      )}

      <div className="login-page">
        <div>
          {props.must && (
            <>
              <img alt="Splash" src="/webf1-center.png" />
              <div className="mb-4 text-left">
                <h5 className="mb-3">Change Password</h5>
                <label className="text-danger">
                  <i className="fa fa-lock me-1"></i> Please reset your password
                </label>
              </div>
            </>
          )}

          <form
            className="pt-2"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (allowSave()) {
                setInProgress(true);
                SysServices.http.myAccount
                  .changePassword(pwData)
                  .then((data: any) => {
                    toastStore.showToast(
                      "Password Updated. Please re-login.",
                      "success"
                    );
                    //No Need To Logout Now Since backend already logouts user
                    //after changing password
                    // SysServices.http.myAccount
                    //   .logout(SysServices.http.fetcher.getToken().sessionId)
                    //   .finally(() => {
                    //     SysServices.http.fetcher.clearToken();
                    //     systemStore.clearAuthData();
                    //     navigate("/");
                    //   });
                    SysServices.http.fetcher.clearToken();
                    systemStore.clearAuthData();
                    navigate("/");
                  })
                  .catch((err: any) => {
                    toastStore.showError("Change Password Failed", err);
                    setInProgress(false);
                  });
              }
            }}
          >
            {!props.must && <h5 className="mb-4 text-left">Change Password</h5>}
            <input
              id="login_password"
              type="password"
              name="current-password"
              placeholder="Current Password"
              className="form-control mb-3"
              value={pwData.currentPassword}
              onChange={(e) => {
                setPwData((p) => {
                  return {
                    ...p,
                    currentPassword: e.target.value,
                  };
                });
              }}
              disabled={inProgress}
            />
            <hr className="my-4" />
            <input
              type="password"
              name="password"
              placeholder="New Password"
              className="form-control mb-3"
              value={pwData.newPassword}
              onChange={(e) => {
                setPwData((p) => {
                  return {
                    ...p,
                    newPassword: e.target.value,
                  };
                });
              }}
              disabled={inProgress}
            />
            <input
              type="password"
              name="password"
              placeholder="Confirm New Password"
              className="form-control mb-2"
              value={pwData.confirmPassword}
              onChange={(e) => {
                setPwData((p) => {
                  return {
                    ...p,
                    confirmPassword: e.target.value,
                  };
                });
              }}
              disabled={inProgress}
            />
            {!passwordMatch() && (
              <div className="text-danger mb-2">
                <span>New Passwords does not match</span>
              </div>
            )}

            <div className="text-right">
              <button
                className="btn btn-primary mt-3"
                type="submit"
                disabled={inProgress || !allowSave()}
              >
                {inProgress ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
