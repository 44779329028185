import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";

function ConfigSetting(props: any) {
  const nav = useNavigate();
  const { area, section } = useParams();
  const isNew = `${area || ""}`.trim().toLowerCase() === "new";

  const [model, setModel] = useState<SysModels.ConfigSettingDto>({
    area: "",
    section: "",
    value: "",
  });

  const current = useFetchHelper(
    async () =>
      SysServices.http.configSetting.get(
        encodeURIComponent(area || ""),
        encodeURIComponent(section || "")
      ),
    "Config Setting"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    await SysServices.http.configSetting
      .save({
        ...model,
      })
      .then((data) => {
        toastStore.showToast("Config Setting Saved", "success");
        nav("/config-settings");
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Config Setting", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!isNew) {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/config-settings");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <div>
      <ConfirmDialog
        show={showDel}
        title="Delete Config Setting"
        message="Do you really want to delete this Config Setting?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.configSetting
              .delete(
                encodeURIComponent(area || ""),
                encodeURIComponent(section || "")
              )
              .then((data) => {
                toastStore.showToast("Config Setting Deleted", "success");
                nav("/config-settings");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Config Setting", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>{isNew ? "Add" : "Edit"} Config Setting</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || isNew) && (
        <div style={{ maxWidth: "400px", width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label>Area</label>
              </div>
              <input
                className="form-control"
                placeholder="Area"
                value={model.area}
                autoFocus={true}
                disabled={!isNew}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      area: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Section</label>
              </div>
              <input
                className="form-control"
                placeholder="Section"
                value={model.section}
                disabled={!isNew}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      section: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Value</label>
              </div>
              <input
                className="form-control"
                placeholder="Value"
                value={model.value}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      value: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="pt-2">
            <button
              className="btn btn-sm btn-primary float-right"
              type="button"
              onClick={(e) => {
                save();
              }}
              disabled={saving || deleting}
            >
              {saving ? "Saving..." : "Submit"}
            </button>
            <button
              className="btn btn-sm btn-secondary me-2 float-right"
              type="button"
              onClick={(e) => {
                nav("/config-settings");
              }}
              disabled={saving || deleting}
            >
              Cancel
            </button>
            {!isNew && (
              <button
                className="btn btn-sm btn-danger"
                type="button"
                onClick={(e) => {
                  setShowDel(true);
                }}
                disabled={saving || deleting}
              >
                {deleting ? "Deleting..." : "Delete"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfigSetting;
