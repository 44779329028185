import { useState } from "react";
import toastStore from "../stores/ToastStore";
import commonService from "./CommonService";

export enum FetchStatus {
  Default = 0,
  InProgress = 1,
  Complete = 2,
  Failed = 3,
}

export const useFetchHelper = <T>(
  serviceAction: () => Promise<T>,
  nameOnMessage: string
) => {
  const [data, setData] = useState<T>();
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.Default);
  const [error, setError] = useState<{ message: string; code: number }>();
  const getData = async () => {
    setStatus(FetchStatus.InProgress);
    await serviceAction()
      .then((rtn) => {
        setData(rtn);
        setError(undefined);
        setStatus(FetchStatus.Complete);
      })
      .catch((err) => {
        const errCode = commonService.getErrorStatusCode(err);
        const errMsg = `Failed getting ${nameOnMessage}`;
        console.log(errMsg);
        if (errCode === 404) {
          toastStore.showToast(
            `<b>Failed Getting ${nameOnMessage}</b> <br/>${nameOnMessage} Not Found.`,
            "error"
          );
        } else {
          toastStore.showError(`Failed Getting ${nameOnMessage}`, err);
        }
        setError({ message: errMsg, code: errCode });
        setStatus(FetchStatus.Failed);
      });
  };

  return {
    data,
    setData,
    getData,
    status,
    error,
    reset: () => {
      setStatus(FetchStatus.Default);
      setData(undefined);
    },
  };
};
