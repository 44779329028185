import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import commonService from "../services/CommonService";
import DateTimePicker from "../components/DateTimePicker";
import dayjs from "dayjs";

function ScheduledJobSetting(props: any) {
  const nav = useNavigate();
  const { jobname } = useParams();
  const [model, setModel] = useState<SysModels.ScheduledJobSettingDto>({});
  const [startDate, setStartDate] = useState(new Date());

  const current = useFetchHelper(
    async () => SysServices.http.scheduledJobSetting.get(jobname || ""),
    "Job Setting"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (jobname) {
      await SysServices.http.scheduledJobSetting
        .update(jobname, {
          cronExpression: model.cronExpression,
          secondsToWaitBeforeStarting: model.secondsToWaitBeforeStarting,
          methodName: commonService.isNullOrEmpty(model.methodName || "")
            ? (null as any)
            : model.methodName,
          canIRun: model.canIRun,
          lastTimeJobRan: model.lastTimeJobRan,
        })
        .then((data) => {
          toastStore.showToast("Job Setting Saved", "success");
          nav("/scheduled-job-settings");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Job Setting", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (jobname && jobname !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/user-types");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (current.data && current.status === FetchStatus.Complete) {
      setStartDate(
        new Date(
          dayjs(current.data.lastTimeJobRan || undefined).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  return (
    <div>
      <ConfirmDialog
        show={showDel}
        title="Delete Job Setting"
        message="Do you really want to delete this Job Setting?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.scheduledJobSetting
              .delete(jobname as any)
              .then((data) => {
                toastStore.showToast("Job Setting Deleted", "success");
                nav("/scheduled-job-settings");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Job Setting", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>Update Scheduled Job Setting</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || jobname === "new") && (
        <div className="flex flex-row flex-wrap gap-20">
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <div className="pt-2">
              <div className="mb-2">
                <div className="mb-1">
                  <label>Name</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Job Name"
                  value={model.jobName || ""}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        jobName: e.target.value,
                      };
                    });
                  }}
                  disabled={true}
                ></input>
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Expression</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Expression"
                  value={model.cronExpression}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        cronExpression: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>
                    Method Name (<small>NameSpace.ClassName,MethodName</small>)
                  </label>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Expression"
                  value={model.methodName}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        methodName: e.target.value,
                      };
                    });
                  }}
                  rows={3}
                />
              </div>
              <div className="mb-2">
                <div className="mb-1">
                  <label>Seconds To Wait Before Starting</label>
                </div>
                <input
                  className="form-control"
                  placeholder="Expression"
                  value={model.secondsToWaitBeforeStarting}
                  type="number"
                  step={1}
                  onChange={(e) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        secondsToWaitBeforeStarting: e.target.value as any,
                      };
                    });
                  }}
                />
              </div>
              <div key={`${current.status}-${startDate}`}>
                <label className="mb-2">Last Time Job Ran</label>
                <div className="mb-3">
                  <DateTimePicker
                    data={startDate}
                    showAllMinuteOptions={true}
                    onChange={(date) => {
                      setModel((rtn) => {
                        return {
                          ...rtn,
                          lastTimeJobRan: date,
                        };
                      });
                    }}
                    disabled={saving}
                  ></DateTimePicker>
                </div>
              </div>
              <div className="mb-2">
                <div className="mb-4">
                  <SwitchButton
                    uncheckedLabel="Can I Run?"
                    checked={model.canIRun || false}
                    onChange={(val) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          canIRun: val || false,
                        };
                      });
                    }}
                  ></SwitchButton>
                </div>
              </div>
            </div>

            <div className="pt-2">
              <button
                className="btn btn-sm btn-primary float-right"
                type="button"
                onClick={(e) => {
                  save();
                }}
                disabled={saving || deleting}
              >
                {saving ? "Saving..." : "Submit"}
              </button>
              <button
                className="btn btn-sm btn-secondary me-2 float-right"
                type="button"
                onClick={(e) => {
                  nav("/scheduled-job-settings");
                }}
                disabled={saving || deleting}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm btn-danger"
                type="button"
                onClick={(e) => {
                  setShowDel(true);
                }}
                disabled={saving || deleting}
              >
                {deleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
          <div className="pt-2">
            <div className="mb-1">
              <label>Next Executions</label>
            </div>
            <div>
              <table className="table table-smx table-bordered">
                <tbody>
                  {current.data?.nextExecutions?.map((nxt) => (
                    <tr key={commonService.toLocalDate(nxt, "full")}>
                      <td>
                        <div className="px-1">
                          <i className="fa fa-calendar me-2"></i>{" "}
                          {commonService.toLocalDate(nxt, "full")}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ScheduledJobSetting;
