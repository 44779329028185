import React, { useEffect, useState } from "react";
import SysModels from "../models";
import dayjs from "dayjs";
import toastStore from "../stores/ToastStore";
import SysServices from "../services";
import systemStore from "../stores/SystemStore";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import CommonSpinner from "../components/CommonSpinner";
import DateTimePicker from "../components/DateTimePicker";
import { Button } from "react-bootstrap";

function SiteDownMessage(props: any) {
  const [siteMessage, setSiteMessage] = useState<SysModels.SiteDownMessageDto>(
    {} as any
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (dayjs(siteMessage.from).isAfter(siteMessage.to)) {
      toastStore.showToast(
        "End Time must be greater than Start Time",
        "warning"
      );
      return;
    }

    setSaving(true);
    await SysServices.http.siteDownMessage
      .save({
        ...siteMessage,
      })
      .then((data) => {
        setSaving(false);
        toastStore.showToast("Site Message Saved.", "success");
        systemStore.setTmoSystemMessage(systemStore.tmoSystemMessage + 1);
      })
      .catch((error) => {
        setSaving(false);
        toastStore.showError("Failed saving Site Message", error);
      });
  };

  const cannotSubmit = () => {
    return (
      saving ||
      !siteMessage ||
      !siteMessage.to ||
      !siteMessage.from ||
      !siteMessage.message?.trim()
    );
  };

  const siteMsgFetcher = useFetchHelper(
    async () => SysServices.http.siteDownMessage.get(),
    "Site Down Message"
  );

  useEffect(() => {
    siteMsgFetcher.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getCounter, setGetCounter] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (siteMsgFetcher.data && siteMsgFetcher.status === FetchStatus.Complete) {
      setGetCounter(getCounter + 1);
      setStartDate(
        new Date(
          dayjs(siteMsgFetcher.data.from || undefined).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        )
      );
      setEndDate(
        new Date(
          dayjs(siteMsgFetcher.data.to || undefined).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        )
      );
      setSiteMessage({
        ...siteMsgFetcher.data,
        from: new Date(
          dayjs(siteMsgFetcher.data.from || undefined).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        ),
        to: new Date(
          dayjs(siteMsgFetcher.data.to || undefined).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteMsgFetcher.status]);

  return (
    <div>
      <div className="mb-3">
        <h4>Site Down Message</h4>
      </div>

      <div style={{ maxWidth: "400px", width: "100%" }}>
        <div>
          {siteMsgFetcher.status === FetchStatus.InProgress && (
            <div className="p-4">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {siteMsgFetcher.status === FetchStatus.Complete && (
            <div>
              <div className="mb-1">
                {[getCounter].map((cnt) => (
                  <React.Fragment key={cnt}>
                    <label className="mb-2">Start Date/Time</label>
                    <div className="mb-3">
                      <DateTimePicker
                        data={startDate}
                        showAllMinuteOptions={true}
                        onChange={(date) => {
                          setSiteMessage((rtn) => {
                            return {
                              ...rtn,
                              from: date,
                            };
                          });
                        }}
                        disabled={saving}
                      ></DateTimePicker>
                    </div>

                    <label className="mb-2">End Date/Time</label>
                    <div className="mb-3">
                      <DateTimePicker
                        data={endDate}
                        showAllMinuteOptions={true}
                        onChange={(date) => {
                          setSiteMessage((rtn) => {
                            return {
                              ...rtn,
                              to: date,
                            };
                          });
                        }}
                        disabled={saving}
                      ></DateTimePicker>
                    </div>
                  </React.Fragment>
                ))}

                <label className="mb-2">Message</label>
                <textarea
                  className="form-control"
                  placeholder="Emails"
                  value={siteMessage?.message}
                  onChange={(e) => {
                    setSiteMessage((rtn) => {
                      return {
                        ...rtn,
                        message: e.target.value || "",
                      };
                    });
                  }}
                  disabled={saving}
                  rows={5}
                  maxLength={2048}
                ></textarea>
                <div className="mt-2 mb-4">
                  <div className="text-right">
                    {siteMessage?.message?.length || 0}/2048
                  </div>
                </div>
              </div>

              <div className="text-right">
                <Button
                  variant="primary"
                  type="button"
                  size="sm"
                  disabled={cannotSubmit()}
                  onClick={() => save()}
                >
                  {saving ? "Saving..." : "Submit"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SiteDownMessage;
