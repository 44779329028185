import React, { useEffect, useState } from "react";
import FormModal from "./FormModal";

function DocEditorLinkDialog(props: {
  type?: "link" | "youtube" | "vimeo";
  onClose: (data?: { text: string; url: string; videoId?: string }) => void;
  data?: {
    text: string;
    url: string;
  };
}) {
  const [data, setData] = useState({
    text: props?.data?.text || "",
    url: props?.data?.url || "",
    videoId: "",
  });

  const getTitle = () => {
    if (props.type === "youtube") {
      return "Youtube Video";
    }
    if (props.type === "vimeo") {
      return "Vimeo Video";
    }
    return "Link";
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.type === "youtube" || props.type === "vimeo") {
        document.getElementById("doc-editor-video-id")?.focus();
      } else {
        document.getElementById("doc-editor-url")?.focus();
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title={getTitle()}
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      submit={() => {
        props.onClose(data);
      }}
      size="sm"
      submitButtonLabel="Done"
    >
      {(props.type === "youtube" || props.type === "vimeo") && (
        <div>
          <label className="required-label">{getTitle()} ID</label>
          <input
            id="doc-editor-video-id"
            type="url"
            placeholder="Video ID"
            className="form-control mb-2"
            onChange={(e) => {
              setData((p) => {
                return {
                  ...p,
                  videoId: e.target.value,
                };
              });
            }}
          />
        </div>
      )}
      {(!props.type || props.type === "link") && (
        <div>
          <label className="required-label">URL</label>
          <input
            id="doc-editor-url"
            type="url"
            placeholder="URL"
            className="form-control mb-2"
            value={data.url}
            onChange={(e) => {
              setData((p) => {
                return {
                  ...p,
                  url: e.target.value,
                };
              });
            }}
          />
          {!props.data?.url && (
            <>
              <label>Text</label>
              <input
                type="text"
                placeholder="Text"
                className="form-control"
                value={data.text}
                onChange={(e) => {
                  setData((p) => {
                    return {
                      ...p,
                      text: e.target.value,
                    };
                  });
                }}
              />
            </>
          )}
        </div>
      )}
    </FormModal>
  );
}

export default DocEditorLinkDialog;
