import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

const FORMAT = "MM/dd/yyyy";
const MINUTES = ["00", "15", "30", "45"];
const HOURS: string[] = [];
let cnt = 0;
while (cnt < 24) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  HOURS.push(i);
  cnt++;
}
const MINUTES_FULL: string[] = [];
cnt = 0;
while (cnt < 60) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  MINUTES_FULL.push(i);
  cnt++;
}
export interface IDateTimePickerProps {
  dayPickerProps?: any; //DayPickerProps;
  dateOnly?: boolean;
  disabled?: boolean;
  style?: object;
  showAllMinuteOptions?: boolean;
  onChange: (data: any, hasChanges?: boolean) => void;
  data: any;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  excludeDates?: Date[] | undefined;
  excludeDateIntervals?: Array<{ start: Date; end: Date }> | undefined;
}

function DateTimePicker(props: IDateTimePickerProps) {
  const [datePart, setDatePart] = useState(
    props?.data ? dayjs(props.data).format("YYYY-MM-DD") : undefined
  );
  const [hhPart, setHHPart] = useState(
    props?.data ? dayjs(props.data).format("HH") : "00"
  );
  const [mmPart, setMMPart] = useState(
    props?.data ? dayjs(props.data).format("mm") : "00"
  );

  useEffect(() => {
    if (datePart) {
      const date = dayjs(`${datePart}T${hhPart}:${mmPart}`).toDate();
      props?.onChange && props.onChange(date);
      //console.log(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePart, hhPart, mmPart]);

  return (
    <div className="sysDateTimePicker">
      <div className="w-100">
        <div className="DayPickerInput w-100">
          <DatePicker
            selected={
              datePart
                ? dayjs(datePart).toDate()
                : props?.data
                ? dayjs(props.data).toDate()
                : undefined
            }
            excludeDateIntervals={props.excludeDateIntervals}
            excludeDates={props.excludeDates}
            onChange={(date) => {
              if (!date) {
                props && props.onChange(null);
                setDatePart(null as any); // !!!
                return;
              }
              console.log(date);
              setDatePart(dayjs(date).format("YYYY-MM-DD"));
            }}
            disabled={props.disabled}
            onBlur={props?.onBlur}
            placeholderText={FORMAT.toUpperCase()}
            dateFormat={FORMAT}
            className="required"
          />
        </div>
      </div>
      {!props.dateOnly && (
        <div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={hhPart}
              onChange={(e) => {
                setHHPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                HOURS.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={mmPart}
              onChange={(e) => {
                setMMPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                (props.showAllMinuteOptions ? MINUTES_FULL : MINUTES).map(
                  (i) => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  )
                )}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateTimePicker;
