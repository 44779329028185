export interface CompanyBaseModelDto {
    companyName?: string;
    active?: boolean;
    timeZone?: string;
}
export interface CompanyOutputDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    companyAddress?: AddressDto;
}
export interface AddressDto {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    mainContactName?: string;
    mainContactPhone?: string;
    mainContactEmail?: string;
}
export interface HttpErrorOutputDto {
    errors?: ErrorOutputDto[];
    statusCode?: number;
    errorSeverityEnum?: ErrorSeverityEnum;
}
export interface ErrorOutputDto {
    fieldName?: string;
    message?: string;
}
export enum ErrorSeverityEnum {
    Information = 0,
    Warning = 1,
    Error = 2,
}
export interface GridOutputBaseDto {
    totalRecords?: number;
}
export interface CompanyGridDto extends GridOutputBaseDto {
    companyDetailItemDtos?: CompanyDetailItemDto[];
}
export interface CompanyDetailItemDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    companyAddress?: AddressDto;
}
export interface SearchInputBaseDto {
    search?: string;
}
export interface CompanySearchDto extends SearchInputBaseDto {
    active?: boolean;
    archived?: boolean;
}
export interface CompanyCreateDto extends CompanyBaseModelDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    companyAddress?: AddressDto;
}
export interface CompanyUpdateDto extends CompanyBaseModelDto {
    archived?: boolean;
    companyAddress?: AddressDto;
}
export interface ConfigSettingDto {
    area?: string;
    section?: string;
    value?: string;
}
export interface EntityBaseDto {
    entityTemplateId?: string;
    userId?: string;
}
export interface EntityOutputBaseDto extends EntityBaseDto {
    id?: string;
    entityTemplateName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    fields?: EntityFieldInGridDto[];
}
export interface EntityOutputDto extends EntityOutputBaseDto {
    fields?: EntityFieldFilledOutputDto[];
}
export interface EntityFieldFilledInDto {
    entityFieldId?: number;
    value?: string;
}
export interface EntityFieldFilledOutputDto extends EntityFieldFilledInDto {
    name?: string;
    fieldType?: WF1FieldType;
    description?: string;
    sequence?: number;
    isFieldRequired?: boolean;
}
export enum WF1FieldType {
    Text = 0,
    TextArea = 1,
    Number = 2,
    Date = 3,
}
export interface EntityFieldInGridDto {
    name?: string;
    fieldType?: WF1FieldType;
    sequence?: number;
    value?: string;
}
export interface EntityGridOutputDto extends GridOutputBaseDto {
    entities?: EntityOutputBaseDto[];
}
export interface EntitySearchInputDto extends SearchInputBaseDto {
}
export interface EntityInputDto extends EntityBaseDto {
    fields?: EntityFieldFilledInDto[];
}
export interface EntityUpdateDto {
    fields?: EntityFieldFilledInDto[];
}
export interface EntityFieldBaseDto {
    name?: string;
    description?: string;
    fieldType?: WF1FieldType;
    isFieldRequired?: boolean;
}
export interface EntityFieldOutputBaseDto extends EntityFieldBaseDto {
    id?: number;
}
export interface EntityFieldOutputDto extends EntityFieldOutputBaseDto {
    canBeUpdateOrDeleted?: boolean;
}
export interface EntityFieldGridOutputDto extends GridOutputBaseDto {
    fields?: EntityFieldOutputBaseDto[];
}
export interface EntityFieldSearchInputDto extends SearchInputBaseDto {
}
export interface TypeAheadOutputDto {
    id?: string;
    label?: string;
    description?: string;
}
export interface TypeAheadInputDto {
    search?: string;
}
export interface EntityFieldInputDto extends EntityFieldBaseDto {
}
export interface EntityTemplateBaseDto {
    name?: string;
    description?: string;
    templateCategoryId?: string;
    userTemplate?: boolean;
}
export interface EntityTemplateOutputBaseDto extends EntityTemplateBaseDto {
    id?: string;
    templateCategoryName?: string;
}
export interface EntityTemplateOutputDto extends EntityTemplateOutputBaseDto {
    canBeUpdatedOrDeleted?: boolean;
    uniqueFieldName?: string;
    fields?: EntityTemplateFieldOutputDto[];
}
export interface EntityTemplateFieldOutputDto extends EntityFieldOutputBaseDto {
    sequence?: number;
    uniqueFieldName?: string;
    isFieldRequired?: boolean;
}
export interface EntityTemplateGridOutputDto extends GridOutputBaseDto {
    templates?: EntityTemplateOutputBaseDto[];
}
export interface EntityTemplateSearchInputDto extends SearchInputBaseDto {
}
export interface EntityTemplateInputDto extends EntityTemplateBaseDto {
    fields?: EntityFieldSequenceDto[];
}
export interface EntityFieldSequenceDto {
    entityFieldId?: number;
    sequence?: number;
    isFieldRequired?: boolean;
}
export interface LookupIntDto {
    label?: string;
    value?: number;
}
export interface GlobalSiteMessageDisplayDto {
    message?: string;
    severity?: ErrorSeverityEnum;
}
export interface GlobalSiteMessageDto {
    severityEnum?: ErrorSeverityEnum;
    from?: Date;
    to?: Date;
    message?: string;
}
export interface HowToOutputDraftDto {
    id?: string;
    role?: RolesEnum;
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
    publishedOn?: Date;
}
export enum RolesEnum {
    AppAdmin = 0,
    Admin = 1,
    User = 2,
    UserManager = 3,
    ReminderManager = 4,
    TemplateManager = 5,
    SaaSAdmin = 6,
}
export interface HowToInputDto {
    role?: RolesEnum;
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
}
export interface HowToSearchDto extends SearchInputBaseDto {
    role?: RolesEnum;
}
export interface UserInitialInputDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    userTimeZone?: string;
}
export interface TokenDto {
    token?: string;
    refreshToken?: string;
    refreshTokenExpiration?: Date;
    sessionId?: string;
}
export interface UserLoginDto {
    userName?: string;
    password?: string;
}
export interface RefreshTokenDto {
    refreshToken?: string;
    userName?: string;
    sessionId?: string;
}
export interface ChangePasswordRequestDto {
    currentPassword?: string;
    newPassword?: string;
}
export interface ForgotPasswordDto {
    userName?: string;
}
export interface ResetPasswordDto {
    userName?: string;
    resetPasswordToken?: string;
    password?: string;
}
export interface ReleaseOutputDto {
    id?: string;
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseInputDto {
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseGridOutputDto extends GridOutputBaseDto {
    releaseOutputDtos?: ReleaseOutputDto[];
}
export interface ReleaseSearchInputDto extends SearchInputBaseDto {
}
export interface ScheduledJobSettingDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    cronDescription?: string;
    nextExecutions?: Date[];
    canIRun?: boolean;
    methodName?: string;
}
export interface ScheduledJobSettingListDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    canIRun?: boolean;
}
export interface ScheduledJobSettingUpdateDto {
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    canIRun?: boolean;
    lastTimeJobRan?: Date;
    methodName?: string;
}
export interface SiteDownMessageDto {
    message?: string;
    from?: Date;
    to?: Date;
}
export interface ErrorMaintenanceGridOutputDto extends GridOutputBaseDto {
    errors?: ErrorMaintenanceDetailOutputDto[];
}
export interface ErrorMaintenanceDetailOutputDto {
    id?: number;
    severityLevel?: string;
    message?: string;
    exception?: string;
    properties?: JsonDocument;
    raiseDate?: Date;
}
export interface JsonDocument {
    isDisposable?: boolean;
    rootElement?: any;
}
export interface ErrorMaintenanceSearchInputDto extends SearchInputBaseDto {
    severityLevel?: ErrorMaintenanceSeverityLevel;
}
export enum ErrorMaintenanceSeverityLevel {
    All = 0,
    Error = 1,
    Warning = 2,
    Information = 3,
    Debug = 4,
}
export interface ErrorMaintenanceDeleteInputDto {
    ids?: number[];
}
export interface TemplateCategoryBaseDto {
    name?: string;
    description?: string;
}
export interface TemplateCategoryOutputBaseDto extends TemplateCategoryBaseDto {
    id?: string;
}
export interface TemplateCategoryOutputDto extends TemplateCategoryOutputBaseDto {
    canBeDeleted?: boolean;
    uniqueFieldName?: string;
}
export interface TemplateCategoryGridOutputDto extends GridOutputBaseDto {
    templateCategories?: TemplateCategoryOutputBaseDto[];
}
export interface TemplateCategorySearchInputDto extends SearchInputBaseDto {
}
export interface TemplateCategoryAvailableDatesOutputDto {
    fieldId?: number;
    fieldName?: string;
    fieldDescription?: string;
}
export interface TemplateCategoryInputDto extends TemplateCategoryBaseDto {
}
export interface UserOutputDto {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
    entityTemplateId?: string;
    roles?: UserRoleOutputDto[];
    userTypeForUserOutputDtos?: UserTypeForUserOutputDto[];
    fields?: EntityFieldFilledOutputDto[];
}
export interface UserRoleOutputDto {
    rolesEnum?: RolesEnum;
    roleName?: string;
}
export interface UserTypeForUserOutputDto {
    id?: number;
    name?: string;
}
export interface UserOutputForReminderManagerDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    entityTemplateName?: string;
    entityTemplateId?: string;
}
export interface UserGridOutputDto extends GridOutputBaseDto {
    users?: UserOutputDto[];
}
export interface UserSearchInputDto extends SearchInputBaseDto {
    allowLogIn?: boolean;
    archived?: boolean;
    allowReminders?: boolean;
    rolesEnum?: RolesEnum;
    userTypeId?: number;
}
export interface UserGridReminderManagerOutputDto extends GridOutputBaseDto {
    users?: UserOutputReminderManagerDto[];
}
export interface UserOutputReminderManagerDto {
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    entityTemplateName?: string;
}
export interface UserSearchReminderManagerInputDto extends SearchInputBaseDto {
}
export interface UserInputBaseDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
}
export interface UserCreateDto extends UserInputBaseDto {
    userName?: string;
    roles?: RolesEnum[];
    sendPasswordEmail?: boolean;
    userTypeIds?: number[];
}
export interface UserUpdateDto extends UserInputBaseDto {
    roles?: RolesEnum[];
    userTypeIds?: number[];
    fields?: EntityFieldFilledInDto[];
}
export interface UserTemplateInputDto {
    templateId?: string;
}
export interface UserSettingBaseDto {
    setting?: string;
}
export interface UserSettingSettingOutputDto extends UserSettingBaseDto {
    settingId?: string;
}
export interface UserSettingSettingInputDto extends UserSettingBaseDto {
}
export interface UserTypeBaseDto {
    name?: string;
    description?: string;
    canManageUsers?: boolean;
    canReceiveAnyReminder?: boolean;
}
export interface UserTypeOutputBaseDto extends UserTypeBaseDto {
    id?: number;
}
export interface UserTypeOutputDto extends UserTypeOutputBaseDto {
    canBeDeleted?: boolean;
}
export interface UserTypeGridOutputDto extends GridOutputBaseDto {
    userTypes?: UserTypeOutputBaseDto[];
}
export interface UserTypeSearchInputDto extends SearchInputBaseDto {
}
export interface UserTypeInputDto extends UserTypeBaseDto {
}
export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
