import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/CommonSpinner";
import YesNoChip from "../components/YesNoChip";
import commonService from "../services/CommonService";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";

function ScheduledJobSettings(props: any) {
  const nav = useNavigate();

  const list = useFetchHelper(
    async () => SysServices.http.scheduledJobSetting.list(),
    "Job Settings"
  );

  useEffect(() => {
    list.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [runJob, setRunJob] = useState<string>();
  const [running, setRunning] = useState<string[]>([]);

  return (
    <div>
      <ConfirmDialog
        show={!!runJob}
        title="Run Job"
        message={`Do you really want to run this job "${runJob}" ?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes" && runJob) {
            const lastJob = runJob;
            setRunning((prev) => {
              return [...prev, lastJob];
            });
            SysServices.http.scheduledJobSetting
              .start(lastJob)
              .then((data) => {
                toastStore.showToast(`${lastJob} job started.`, "success");
              })
              .catch((err) => {
                toastStore.showError("Failed to Run Job", err);
              })
              .finally(() => {
                setRunning((prev) => {
                  return prev.filter((j) => j !== lastJob);
                });
              });
          }
          setRunJob(undefined);
        }}
      ></ConfirmDialog>
      <div className="mb-3 flex flex-row">
        <h4 className="flex-1">Scheduled Job Settings</h4>
        <div className="flex-0">
          <button
            className="btn btn-primary no-wrap"
            type="button"
            disabled={list.status === FetchStatus.InProgress}
            onClick={(e) => {
              list.getData();
            }}
          >
            <i className="fa fa-refresh"></i>
          </button>
        </div>
      </div>
      {list.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {list.status !== FetchStatus.InProgress && (
        <>
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="w-30">Name</th>
                <th className="w-40">Expression</th>
                <th className="no-wrap w-20">Last Run</th>
                <th className="text-center w-10 no-wrap">
                  <span className="me-2">Time to Wait</span>
                  <i
                    className="fa fa-question-circle pointer text-primary"
                    title="Seconds to Wait Before Starting"
                  ></i>
                </th>
                <th className="w-10 no-wrap">Run Job</th>
              </tr>
            </thead>
            <tbody>
              {!list.data && (
                <tr>
                  <td colSpan={4}>No Record(s) Found</td>
                </tr>
              )}
              {list?.data?.map((item) => (
                <tr
                  key={item.jobName}
                  className="pointer"
                  onClick={(e) => {
                    nav(`/scheduled-job-settings/${item.jobName}`);
                  }}
                >
                  <td>{item.jobName}</td>
                  <td>{item.cronExpression}</td>
                  <td>
                    <small>
                      {item.lastTimeJobRan &&
                        commonService.toLocalDate(item.lastTimeJobRan, "full")}
                    </small>
                  </td>
                  <td className="text-center">
                    {item.secondsToWaitBeforeStarting}
                  </td>
                  <td>
                    <button
                      type="button"
                      className={`btn btn-sm btn-primary no-wrap`}
                      disabled={
                        !item.canIRun || running.includes(item.jobName || "")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setRunJob(item.jobName);
                      }}
                    >
                      {running.includes(item.jobName || "") ? (
                        <>
                          <i className="fa fa-spin fa-spinner me-2"></i>Running
                        </>
                      ) : (
                        <>
                          <i className="fa fa-play me-2"></i>Run
                        </>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default ScheduledJobSettings;
